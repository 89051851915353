import styled from 'styled-components'

import { Link } from 'components/link'
import { Completed } from 'icon'

const CompletedView = (props) => {
  const { message, btn, link } = props

  return (
    <PageView>
      <Completed />
      <Message>{message}</Message>
      <Link to={link}>
        <Btn>{btn}</Btn>
      </Link>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 90vh;
  max-width: ${(p) => p.theme.app_max};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 16px;
  padding: 0px 20px;
`

const Message = styled.div`
  width: 100%;
  text-align: center;
  margin: 24px 0px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

const Btn = styled.div`
  min-width: 200px;
  padding: 0px 24px;
  height: 50px;
  border-radius: 14px;
  color: white;
  background-color: ${(p) => p.theme.color.red};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default CompletedView
