import styled from 'styled-components'

import { Link } from 'components/link'

const CardView = (props) => {
  const { link, children } = props

  const content = link ? <Link to={link}>{children}</Link> : children

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  margin-top: 8px;
  padding: 10px 20px;
  border-radius: 12px;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
`

export default CardView
