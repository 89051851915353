import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const original = {
  company_id: '',
  request_part_at: timer.get().subtract(1, 'months'),
  request_future_at: timer.get().add(1, 'months'),
}

let state
export class OvertimeConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      setting: cloneDeep(original),
      category_menu: [],
    })

    state = this
  }

  async getConfig({ force = false } = {}) {
    const store = this.toJS()
    console.log('store:', store)
    if (force === false && store.setting.company_id) {
      return { category_menu: store.category_menu }
    }

    const url = `${config.api}/v1/user/overtime/config`
    const res = await http.get(url)

    const newDoc = res.body || {}

    const { setting = {}, category_list = [] } = newDoc
    const category_menu = category_list.map((it) => {
      return {
        name: it.category_name,
        value: it.ot_category_id,
      }
    })

    setting.request_part_at = timer.get(setting.request_part_at)
    setting.request_future_at = timer.get(setting.request_future_at)
    console.log('category_menu:', category_menu)
    runInAction(() => {
      state.setting = setting
      state.category_menu = category_menu
    })

    return { category_menu }
  }
}

export default new OvertimeConfig()
