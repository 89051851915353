import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Dashboard extends BaseStore {
  constructor() {
    super()
    this.observable({
      leave_team_list: [],
      leave_my_list: [],
      task_list: [],
      reimburse_total: 0,
    })

    state = this
  }

  async getLeaveTeam() {
    const start = timer.getDate().toISOString()
    const finish = timer.get().endOf('day').toISOString()

    const url = `${config.api}/v1/user/leave-request/team/${start}/${finish}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.leave_team_list = list
    })
  }

  async getLeaveMyTeam() {
    const start = timer.getDate().toISOString()
    const finish = timer.get().endOf('day').toISOString()

    const url = `${config.api}/v1/user/leave-request/team/${start}/${finish}/my-team`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.leave_my_list = list
    })
  }

  async getReimbureTotal() {
    const date = timer.get().toISOString()
    const url = `${config.api}/v1/user/reimbursement/report/pending-total/${date}`
    const res = await http.get(url)

    const { total } = res.body || {}

    runInAction(() => {
      state.reimburse_total = total || 0
    })
  }
}

export default new Dashboard()
