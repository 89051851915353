import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import LoginPage from 'pages/login'
import LeaveSubmit from 'pages/leaveReq/submit'

const UserApp = (props) => {
  const isLogin = props.member.isLogin()
  if (!isLogin) {
    return (
      <Routes>
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/leave" element={<LeaveSubmit />} />
    </Routes>
  )
}

export default inject('member')(observer(UserApp))
