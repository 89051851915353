import { Next } from 'icon'

import BaseBtn from './BaseBtn'

const NextBtn = (props) => {
  const { disabled, onClick, loading, tag } = props

  const bg = disabled ? '#626262' : '#F36273'
  return (
    <BaseBtn
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      tag={tag}
      icon={<Next size={0.8} color="white" />}
      color={'white'}
      bg_color={bg}
      border_color={bg}
    />
  )
}

export default NextBtn
