/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#626262' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke={color}/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
