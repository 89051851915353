/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = 'white' } = props

  const width = `${Math.ceil(23 * size)}`
  const height = `${Math.ceil(26 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 22.8491V3.44827C0 1.08449 2.60655 -0.350765 4.60406 0.913112L20.7714 11.1426C22.686 12.3541 22.6183 15.1693 20.6476 16.2872L4.48023 25.4585C2.48032 26.593 0 25.1484 0 22.8491Z" fill={color}/>
    </svg>
    
  )
}
/* eslint-enable */
export default Logo
