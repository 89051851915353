/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props
  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(30 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33333 30L16.6667 30C17.5496 29.9965 18.3953 29.6567 19.0196 29.0547C19.644 28.4526 19.9963 27.6371 20 26.7857L20 3.21429C19.9963 2.36289 19.644 1.54737 19.0197 0.945337C18.3953 0.343304 17.5496 0.00352528 16.6667 -2.91409e-07L3.33333 -1.45705e-06C2.45041 0.00352396 1.60468 0.343303 0.980351 0.945336C0.356021 1.54737 0.00365848 2.36289 2.34168e-06 3.21429L2.81002e-07 26.7857C0.00365627 27.6371 0.356018 28.4526 0.980349 29.0547C1.60468 29.6567 2.4504 29.9965 3.33333 30Z" fill="black"/>
      <circle cx="10" cy="26.5" r="1.5" fill="white"/>
      <rect x="2" y="3" width="16" height="20" rx="1" fill="white"/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
