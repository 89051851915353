import styled from 'styled-components'
import { Button } from 'antd'

const Btn = (props) => {
  const {
    disabled,
    children,
    text,
    name,
    loading,
    size,
    onClick,
    color,
    backgroundColor,
    border,
  } = props

  const onBtn = () => {
    if (onClick) onClick(name)
  }

  const getHeight = () => {
    switch (size) {
      case 'xs':
        return 'xs'
      case 'md':
        return 'md'
      default:
        return undefined
    }
  }

  const css = getHeight()
  const click = !loading && !disabled ? onBtn : undefined
  return (
    <PageView
      className={css}
      color={color}
      backgroundColor={backgroundColor}
      border={border}
    >
      <Button disabled={disabled} loading={loading} onClick={click}>
        {children || text}
      </Button>
    </PageView>
  )
}

const getBorder = (props) => {
  const { border } = props

  return border ? `1px solid ${border};` : '0px'
}

const PageView = styled.div`
  button {
    color: ${(p) => p.color};
    background-color: ${(p) => p.backgroundColor};
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    border-radius: 15px;
    border: ${getBorder};
  }

  button:active,
  button:focus,
  button:hover {
    color: ${(p) => p.color};
    background-color: ${(p) => p.backgroundColor};
    border: ${getBorder};
  }

  button:hover > button:disabled {
    color: ${(p) => p.color};
    background-color: ${(p) => p.backgroundColor};
    border: ${getBorder};
  }

  &.xs > button {
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 17px;
  }

  &.md > button {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
`

export default Btn
