const Logo = (props) => {
  const { size = 1, color = '#1F1F1F' } = props
  const width = `${Math.ceil(9 * size)}`
  const height = `${Math.ceil(18 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M1.49293 8.7082e-05C1.68499 -0.000565214 1.87475 0.0418237 2.04828 0.124137C2.22181 0.206453 2.37469 0.326602 2.49569 0.475757L8.7051 8.18931C8.89419 8.41935 8.99756 8.70789 8.99756 9.00567C8.99756 9.30344 8.89419 9.59198 8.7051 9.82202L2.27714 17.5356C2.05892 17.7981 1.74535 17.9632 1.40541 17.9946C1.06546 18.0259 0.72699 17.9209 0.464451 17.7027C0.201911 17.4845 0.03681 17.1709 0.00546752 16.831C-0.025875 16.491 0.0791084 16.1526 0.297323 15.89L6.04393 8.99924L0.490164 2.10846C0.332958 1.91975 0.233097 1.68997 0.202398 1.44628C0.171698 1.2026 0.211444 0.955222 0.316935 0.733421C0.422426 0.511621 0.589244 0.324682 0.797652 0.194721C1.00606 0.0647595 1.24733 -0.00278159 1.49293 8.7082e-05Z"
        fill={color}
      />
    </svg>
  )
}

export default Logo
