import styled from 'styled-components'

const ContentView = (props) => {
  const { isNotFound, text, font = '22px', children } = props
  if (!isNotFound) {
    return children
  }

  return (
    <PageView>
      <Text style={{ fontSize: font }}>{text}</Text>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
`

const Text = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${(p) => p.theme.color_level.grey.low};
`

export default ContentView
