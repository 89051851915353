import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const original_leave_type = {
  type: 'unused',
  value: 0,
  carry_day: 0,
  display_balance: true,
}

const original = {
  company_id: '',
  tag: '',
  version: '',
  notification: {
    email: '',
    hr_email: '',
    slack: {
      enabled: false,
      channel: '',
      token: '',
    },
  },
  leave_type: {
    sick: cloneDeep(original_leave_type),
    business: cloneDeep(original_leave_type),
    vacation: cloneDeep(original_leave_type),
    information: cloneDeep(original_leave_type),
    other: cloneDeep(original_leave_type),
  },
  leave_request: {
    submit_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
    cancel_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },

    submit_approve: {
      type: 'verify',
      past_day: 1,
    },
    cancel_approve: {
      type: 'verify',
      past_day: 1,
    },

    approval: {
      line_manager: true,
      project_manager: true,
    },
  },
}

const getPastDate = (days) => {
  return timer.getDate().subtract(days || 0, 'days')
}

let state
export class LeaveConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })

    state = this
  }

  async getConfig({ force = false } = {}) {
    const doc = this.toJS().doc
    if (force === false && doc.config_leave_id) {
      return
    }

    const url = `${config.api}/v1/user/leave-request/leave-config`
    const res = await http.get(url)

    const newDoc = res.body

    const { leave_request = {} } = newDoc

    leave_request.submit_disable.past_date = getPastDate(
      leave_request.submit_disable.past_day
    )
    leave_request.cancel_disable.past_date = getPastDate(
      leave_request.cancel_disable.past_day
    )

    newDoc.leave_request = leave_request
    runInAction(() => {
      state.doc = newDoc
    })
  }
}

export default new LeaveConfig()
