import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const Button = (props) => {
  const { text, onClick } = props

  return (
    <PageView>
      <Btn>
        <PrimaryBtn text={text} onClick={onClick} />
      </Btn>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 16px;

  ${(p) => p.theme.media.tablet} {
    padding-top: 0px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: ${(p) => p.theme.color.backgroundColor};
  }
`

const Btn = styled.div`
  width: 100%;
  max-width: 314px;
  margin: 0 auto;
  padding: 16px;
`

export default Button
