import styled from 'styled-components'
import { Button } from 'antd'

const IconBtn = (props) => {
  const onClick = () => {
    const { name, onClick } = props
    if (onClick) onClick(name)
  }

  const { disabled, icon, text, loading, style } = props

  let click
  if (!loading && !disabled) {
    click = onClick
  }

  const txt = loading ? (
    text
  ) : (
    <Text>
      {icon} {text}
    </Text>
  )
  return (
    <PageView style={style}>
      <Button disabled={disabled} loading={loading} onClick={click}>
        {txt}
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  button {
    color: ${(p) => p.theme.color.black};
    background-color: white;
    width: 100%;
    height: 48px;
    border-radius: 39px;
    border: 1px solid #b4d5d7;
    font-weight: 700;
  }

  button:active,
  button:focus,
  button:hover {
    color: ${(p) => p.theme.color.black};
    border: 1px solid ${(p) => p.theme.color.red};
  }
`

const Text = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    left: 10px;
  }
`

export default IconBtn
