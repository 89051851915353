import styled from 'styled-components'
import { Row } from 'antd'

const RowItem = (props) => {
  const {
    justify,
    align,
    style = {},
    padding = '0px 0px 16px 0px',
    children,
  } = props
  style.padding = padding
  return (
    <PageView style={style}>
      <Row justify={justify} align={align}>
        {children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default RowItem
