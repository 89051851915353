import styled from 'styled-components'

import Loading from 'components/loading'
import { Logo, Back, AddRequest } from 'icon'

const BackHeader = (props) => {
  const {
    loading,
    onBack,
    onClick,
    icon,
    title,
    children,
    backgroundColor,
    padding,
  } = props

  const getIcon = () => {
    switch (icon) {
      case 'add':
        return <AddRequest />
      default:
        return icon
    }
  }

  const header = title ? <Title>{title}</Title> : <Logo size={0.5} />
  const actionBtn = onClick ? (
    <ActionBtn onClick={onClick}>{getIcon()}</ActionBtn>
  ) : undefined
  return (
    <PageView>
      <ContentView>
        <Header>
          <Btn onClick={onBack}>
            <Back size={1} stroke="2" />
          </Btn>

          <Content>{header}</Content>
          {actionBtn}
        </Header>
      </ContentView>
      <Body style={{ backgroundColor, padding }}>
        <Loading loading={loading}>{children}</Loading>
      </Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  height: 50px;
  max-width: ${(p) => p.theme.app_max};
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

const ContentView = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  height: 55px;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 8px;
`

const ActionBtn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  padding: 8px;
`

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(p) => p.theme.color_level.grey.high};
  text-align: center;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Body = styled.div`
  width: 100%;
  min-height: 75vh;
  max-width: ${(p) => p.theme.app_max};
  margin: 0 auto;
  padding: 36px;
  padding-top: 55px;
`

export default BackHeader
