import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Row, Col } from 'components/display'
import { InputArea, DropdownList } from 'components/input'
import { ImgPicker } from 'components/picker'
import { file, timer, leaveReq, leaveBalance } from 'utils'
import { config } from 'config'
import { message } from 'message'

import LeaveDate from './LeaveDate'

const lastYear = timer.getDate().subtract(1, 'year')

const Information = (props) => {
  const [typeList, setTypeList] = useState([])
  const { validate = {}, onValidate, lang } = props

  const { holiday_list = [] } = props.holiday.toJS()
  const leave_config = props.leaveConfig.toJS().doc
  const { submit_disable } = leave_config.leave_request

  const { doc, leave_type, available_day } = props.leaveReq.toJS()
  const { leave_category } = doc

  const getAvailableDay = useCallback(async () => {
    try {
      const isCounter = leaveBalance.isCounterBalance(
        leave_config,
        leave_category,
        leave_type
      )
      if (!isCounter) {
        props.leaveReq.setAvailableDay()
        return
      }

      const balance_day = await props.leaveBalance.getAvailableBalance({
        leave_req: doc,
        leave_config,
        leave_type,
      })

      props.leaveReq.setAvailableDay(balance_day)
    } catch (e) {
      message.error(e.message)
      props.leaveReq.setAvailableDay()
    }
  }, [props.leaveBalance, props.leaveReq, leave_category, leave_type])

  useEffect(() => {
    const { type_list } = props.leaveType.toJS()
    const filter_list =
      leave_category === undefined
        ? []
        : type_list.filter((it) => it.category === leave_category)

    const list = filter_list.map((it) => {
      return { name: it.name, value: it.leave_type_id, tag: it }
    })

    setTypeList(list)
  }, [props.leaveType, leave_category])

  useEffect(() => {
    getAvailableDay()
  }, [
    getAvailableDay,
    leave_category,
    leave_type,
    doc.leave_type,
    doc.start_date,
    doc.end_date,
  ])

  const onChange = (val, name) => {
    doc[name] = val
    props.leaveReq.setDoc(doc)
  }

  const onLeaveTypeItem = (val, name, tag) => {
    doc[name] = val
    props.leaveReq.setDocLeaveType(doc, tag)

    delete validate.leave_type_id
    delete validate.available_day
    onValidate(validate)
  }

  const onLeavePeriodType = (val) => {
    doc.leave_type = val
    doc.leave_day =
      val === 'one'
        ? doc.start_type === 'full'
          ? 1
          : 0.5
        : leaveReq.calcLeaveDay(doc, holiday_list, leave_type)

    props.leaveReq.setDoc(doc)

    delete validate.available_day
    onValidate(validate)
  }

  const onDate = (newDoc, name) => {
    if (name === 'start_date') {
      const days = newDoc.end_date.diff(newDoc.start_date, 'days')
      const date = timer.get(newDoc.start_date).startOf('days').add(1, 'day')
      newDoc.end_date =
        days === 0 || newDoc.end_date < newDoc.start_date
          ? timer.getDateNoHoliday(date, holiday_list)
          : newDoc.end_date
    }

    newDoc.leave_day = leaveReq.calcLeaveDay(newDoc, holiday_list, leave_type)
    props.leaveReq.setDoc(newDoc)

    delete validate.available_day
    onValidate(validate)
  }

  const onImage = async (value, name, obj) => {
    if (value) {
      const { text, size } = await file.compress({
        value,
        limit: config.image.size,
      })
      doc.file_url = text
      doc.file_name = obj.name
      doc.file_size = size
    } else {
      doc.file_url = undefined
      doc.file_name = ''
      doc.file_size = 0
    }

    props.leaveReq.setDoc(doc)
  }

  const getInfo = (name, val, isError) => {
    const note = isError ? (
      <ErrorNote>
        {lang.getMessage('Insufficient Leave Balance', 'วันลาไม่เพียงพอ')}
      </ErrorNote>
    ) : undefined
    return (
      <Info className={isError ? 'error' : ''}>
        <Name>{name}</Name>
        <Value>{val}</Value>
        {note}
      </Info>
    )
  }

  const minDate =
    submit_disable.type === 'unused' ? lastYear : submit_disable.past_date

  const endDate =
    doc.leave_type === 'period' ? (
      <LeaveDate
        label={lang.getMessage('Finish Date', 'วันสิ้นสุด')}
        name="end_date"
        name_type="end_type"
        doc={doc}
        menu={lang.getMessage(dateTypeMenu.eng, dateTypeMenu.th)}
        holiday_list={holiday_list}
        minDate={doc.start_date}
        onChange={onDate}
      />
    ) : undefined

  return (
    <PageView>
      <Row align="baseline">
        <Col {...col} position="left-responsive">
          <DropdownList
            label={lang.getMessage('Leave Type', 'ประเภทการลา')}
            name="leave_type_id"
            menu={typeList}
            disabled={typeList.length === 0}
            value={doc.leave_type_id}
            onChange={onLeaveTypeItem}
            invalid={lang.getValidate(
              validate['leave_type_id'],
              'leave type',
              'ประเภทการลา'
            )}
          />
        </Col>
        <Col {...col} position="right-responsive">
          <DropdownList
            label={lang.getMessage('Period', 'ช่วงวันลา')}
            name="leave_type"
            menu={lang.getMessage(leaveTypeMenu.eng, leaveTypeMenu.th)}
            value={doc.leave_type}
            onChange={onLeavePeriodType}
          />
        </Col>
      </Row>

      <LeaveDate
        label={lang.getMessage('Start Date', 'วันเริ่มต้น')}
        name="start_date"
        name_type="start_type"
        doc={doc}
        menu={lang.getMessage(dateTypeMenu.eng, dateTypeMenu.th)}
        holiday_list={holiday_list}
        minDate={minDate}
        onChange={onDate}
      />

      {endDate}

      <Row align="baseline">
        <Col {...col} position="left-responsive">
          <InputArea
            label={lang.getMessage('Note', 'ข้อความ')}
            name="note"
            rows={3}
            value={doc.note}
            onChange={onChange}
          />
        </Col>
        <Col {...col} position="right-responsive">
          <ImgPicker
            label={lang.getMessage('Choose image', 'เลือกรูปภาพ')}
            height={101}
            name="file_url"
            value={doc.file_url}
            file_name={doc.file_name}
            file_size={doc.file_size}
            onChange={onImage}
          />
        </Col>
      </Row>

      <Area>
        {getInfo(lang.getMessage('Used', 'วันที่ใช้'), doc.leave_day)}
        <Line />
        {getInfo(
          lang.getMessage('Available', 'วันที่พร้อมใช้'),
          available_day === undefined ? '-' : available_day,
          validate.available_day
        )}
      </Area>
    </PageView>
  )
}

const col = { lg: 12, md: 12, xs: 24 }
const leaveTypeMenu = {
  eng: [
    { name: 'One day', value: 'one' },
    { name: 'Period', value: 'period' },
  ],
  th: [
    { name: 'หนึ่งวัน', value: 'one' },
    { name: 'ช่วงวัน', value: 'period' },
  ],
}

const dateTypeMenu = {
  eng: [
    { name: 'All day', value: 'full' },
    { name: 'AM', value: 'am' },
    { name: 'PM', value: 'pm' },
  ],
  th: [
    { name: 'ทั้งวัน', value: 'full' },
    { name: 'เช้า', value: 'am' },
    { name: 'บ่าย', value: 'pm' },
  ],
}

const PageView = styled.div`
  width: 100%;
`

const Area = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 18px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Line = styled.div`
  width: 10%;
  padding-bottom: 18px;
`

const Info = styled.div`
  text-align: center;
  width: 45%;
  font-weight: 700;
  font-size: 18px;

  &.error {
    color: ${(p) => p.theme.color.red};
  }
`

const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`

const Value = styled.div`
  font-weight: 700;
  font-size: 18px;
`

const ErrorNote = styled.div`
  font-weight: 400;
  font-size: 14px;
`

export default inject(
  'lang',
  'holiday',
  'leaveReq',
  'leaveConfig',
  'leaveBalance',
  'leaveType'
)(observer(Information))
