import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Vocation, Sick, Business, Information, Other } from 'icon'

const Category = (props) => {
  const { onValidate, lang } = props

  const { doc } = props.leaveReq.toJS()
  const { leave_category } = doc

  const content = menu_list.map((it, i) => {
    const { name, name_th, icon, value } = it

    const isActive = leave_category === value
    const css = isActive ? value : undefined
    const cssIcon = isActive ? 'active' : undefined
    const onClick = isActive
      ? undefined
      : () => {
          doc.leave_category = value
          doc.leave_type_id = undefined
          props.leaveReq.setDocLeaveType(doc, undefined)

          onValidate({})
        }
    return (
      <Button key={i} className={css} onClick={onClick}>
        <Icon className={cssIcon}>{icon}</Icon>
        <Name>{lang.getMessage(name, name_th)}</Name>
      </Button>
    )
  })

  return <PageView>{content}</PageView>
}

const menu_list = [
  {
    name: 'Vacation leave',
    name_th: 'พักร้อน',
    icon: <Vocation />,
    value: 'vacation',
  },
  { name: 'Sick leave', name_th: 'ลาป่วย', icon: <Sick />, value: 'sick' },
  {
    name: 'Business leave',
    name_th: 'ลากิจ',
    icon: <Business />,
    value: 'business',
  },
  {
    name: 'Information',
    name_th: 'แจ้งข่าวสาร',
    icon: <Information />,
    value: 'information',
  },
  { name: 'Other', name_th: 'ลาอื่นๆ', icon: <Other />, value: 'other' },
]

const PageView = styled.div`
  width: 100%;
  padding: 24px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Button = styled.div`
  font-weight: bold;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 16px;
  padding: 16px;
  width: 24%;
  margin-bottom: 12px;
  cursor: pointer;

  &.vacation {
    cursor: default;
    background-color: ${(p) => p.theme.leave_category.vacation.bg};
  }

  &.sick {
    cursor: default;
    background-color: ${(p) => p.theme.leave_category.sick.bg};
  }

  &.business {
    cursor: default;
    background-color: ${(p) => p.theme.leave_category.business.bg};
  }

  &.information {
    cursor: default;
    background-color: ${(p) => p.theme.leave_category.information.bg};
  }

  &.other {
    cursor: default;
    background-color: ${(p) => p.theme.leave_category.other.bg};
  }

  ${(p) => p.theme.media.mobile} {
    width: 48%;
    margin-bottom: 16px;
  }
`

const Icon = styled.span`
  height: 28px;
  border-radius: 40%;
  padding: 1px 4px 0px 4px;
  display: inline-block;

  &.active {
    background-color: white;
  }
`

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-top: 5px;
`

export default inject('lang', 'leaveReq')(observer(Category))
