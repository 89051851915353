export const isCounterBalance = (leave_config, leave_category, leave_type) => {
  if (!leave_category) {
    return false
  }

  const category = leave_config.leave_type[leave_category]

  const isCategoryCounter = category ? category.type !== 'unused' : false
  const isLeaveCounter = leave_type
    ? leave_type.balance_type !== 'unused'
    : false

  return isCategoryCounter || isLeaveCounter
}

export const getAvailable = (item) => {
  if (!item) {
    return undefined
  }

  const total = item.total_balance || 0
  const used_days = item.balance_day || 0
  return total - used_days
}

export const getAvailableBalance = (
  balance_list = [],
  leave_req = {},
  leave_type = { balance_type: 'unused' }
) => {
  const { leave_category } = leave_req
  const { balance_type, leave_type_id } = leave_type

  const balance_category = balance_list.find((it) => {
    return it.type === 'category' && it.category === leave_category
  })

  let balance_day = 0
  if (balance_type !== 'unused') {
    const balance_leave = balance_list.find((it) => {
      return it.type === 'leave_type' && it.leave_type_id === leave_type_id
    })

    balance_day = getAvailable(balance_leave)
  } else {
    balance_day = getAvailable(balance_category)
  }

  return balance_day
}

export const isSameBalance = ({
  leave_config = { leave_type: {} },
  leave_req = {},
  leave_type,
} = {}) => {
  const { leave_category } = leave_req
  const category = leave_config.leave_type[leave_category] || {
    type: 'unused',
  }

  const balance_type = leave_type ? leave_type.balance_type : category.type

  const format = balance_type === 'per_year' ? 'YY' : 'MMYY'
  const startTxt = leave_req.start_date.format(format)
  const endTxt = leave_req.end_date.format(format)

  const isSame = startTxt === endTxt
  return { isSame, balance_type }
}
