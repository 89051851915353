import styled from 'styled-components'

import { Edit } from 'icon'

const ProfilePicker = (props) => {
  const {
    name = 'get file',
    readonly = false,
    disabled = false,
    label = 'Import',
    onChange = () => {},
    extAccept = '.jpg, .jpeg, .png',
    img_url,
  } = props

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onCh = async (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result, name, file)
    })
    reader.readAsDataURL(file)
  }

  const disable = readonly || disabled
  const css = disable ? 'disable' : ''
  const content = img_url ? <Image src={img_url} /> : label
  return (
    <Section className={css}>
      <input
        id={name}
        type="file"
        accept={extAccept}
        style={{ display: 'none' }}
        onChange={onCh}
        onClick={onClick}
      />

      <label readOnly={disable} htmlFor={disable ? '' : name}>
        {content}
        <Icon>
          <Edit color="#3F3F3F" size={0.8} />
        </Icon>
      </label>
    </Section>
  )
}

const Section = styled.div`
  font-size: 14px;

  width: 90px;
  height: 90px;
  margin: 0 auto;
  color: white;
  background-color: ${(p) => p.theme.color_level.grey.medium};
  border-radius: 50%;
  position: relative;

  &.disable {
    background-color: ${(p) => p.theme.color.disable};
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 90px;
  }
`

const Icon = styled.span`
  cursor: pointer;
  background-color: ${(p) => p.theme.color_level.grey.low};
  border-radius: 50%;
  border: 1px solid white;
  padding: 2px 4px;

  position: absolute;
  top: 0px;
  right: -4px;
`

const Image = styled.img`
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
`

export default ProfilePicker
