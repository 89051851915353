/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props

  const width = `${Math.ceil(36 * size)}`
  const height = `${Math.ceil(36 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.758 6.24209C11.5069 5.99101 11.0999 5.99101 10.8489 6.24209L8.99999 8.09094L7.15107 6.24201C6.89999 5.99094 6.49299 5.99094 6.24192 6.24201C5.99085 6.49309 5.99085 6.90009 6.24192 7.15116L8.09085 9.00009L6.24192 10.849C5.99085 11.1001 5.99085 11.5071 6.24192 11.7582C6.36742 11.8837 6.53199 11.9464 6.69649 11.9464C6.86099 11.9464 7.02549 11.8837 7.15107 11.7582L8.99999 9.90923L10.8489 11.7582C10.9744 11.8837 11.139 11.9464 11.3035 11.9464C11.468 11.9464 11.6325 11.8837 11.7581 11.7582C12.0091 11.5071 12.0091 11.1001 11.7581 10.849L9.90914 9.00009L11.7581 7.15116C12.0091 6.90016 12.0091 6.49309 11.758 6.24209Z"
        fill={color}
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
