import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class DocumentList extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
    })

    state = this
  }

  async getList({ date } = {}) {
    const q = date ? `?release_at=${date.toISOString()}` : ''
    const url = `${config.api}/v1/user/document/report${q}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.list = list
    })
  }
}

export default new DocumentList()
