import styled from 'styled-components'
import { Modal } from 'antd'

import { Close } from 'icon'

const BaseDialog = (props) => {
  const {
    id,
    visible,
    title = '',
    save = 'Done',
    maxWidth = '500px',
    onClose,
    onSave,
    children,
    padding,
  } = props

  const onClickInside = (e) => {
    e.stopPropagation()
  }

  const closeBtn = onClose ? (
    <CloseMenu onClick={onClose}>
      <Close size={0.75} />
    </CloseMenu>
  ) : (
    <CloseMenu />
  )

  const saveBtn = onSave ? (
    <SaveMenu onClick={onSave}>{save}</SaveMenu>
  ) : (
    <SaveMenu />
  )

  return (
    <Modal
      id={id}
      title=""
      footer=""
      open={visible}
      className="full-dialog-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <PageView onClick={onClose}>
        <PageArea>
          <PageBody maxWidth={maxWidth} onClick={onClickInside}>
            <TopMenu>
              {closeBtn}
              <TitleMenu>{title}</TitleMenu>
              {saveBtn}
            </TopMenu>
            <BodyContent style={{ padding }}>{children}</BodyContent>
          </PageBody>
        </PageArea>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: auto;
  background-color: rgba(50, 50, 50, 0.01);
`

const PageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const PageBody = styled.div`
  max-width: ${(p) => p.maxWidth};
  width: 100%;
  border-radius: 25px;

  ${(p) => p.theme.media.mobile} {
    height: 100vh;
    max-width: 100%;
  }
`

const BodyContent = styled.div`
  width: 100%;
  min-height: 25vh;
  max-height: 70vh;
  overflow: auto;
  padding: 16px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  ${(p) => p.theme.media.mobile} {
    min-height: calc(100vh - 55px);
    max-height: 100vh;
    border-radius: 0px;
  }
`

const TopMenu = styled.div`
  width: 100%;
  height: 55px;
  position: relative;
  padding: 16px;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0.4px 1px rgba(0, 0, 0, 0.25);

  ${(p) => p.theme.media.mobile} {
    border-radius: 0px;
  }
`

const TitleMenu = styled.div`
  width: calc(100% - 100px);
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`

const CloseMenu = styled.div`
  width: 40px;
  height: 18px;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
`

const SaveMenu = styled.div`
  width: 60px;
  padding: 8px 0px 8px 8px;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${(p) => p.theme.color.red};
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
`

export default BaseDialog
