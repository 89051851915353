import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  company_id: '',
  holiday_year: undefined,
  data: {
    saturday: true,
    sunday: true,
    january: [],
    february: [],
    march: [],
    april: [],
    may: [],
    june: [],
    july: [],
    august: [],
    september: [],
    october: [],
    november: [],
    december: [],
  },
}

let state
export class Holiday extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      holiday_list: [],
      next_holiday: {},
    })

    state = this
  }

  async getNextHoliday() {
    const url = `${config.api}/v1/user/account/holiday/my-company/coming`
    const res = await http.get(url)
    const data = res.body

    runInAction(() => {
      state.next_holiday = data || {}
    })
  }

  async getYearHoliday(year) {
    const url = `${config.api}/v1/user/account/holiday/${year}`
    const res = await http.get(url)

    return res.body
  }

  async getHolidayList({ force = false } = {}) {
    const list = this.toJS().holiday_list
    if (force === false && list.length > 0) {
      return list
    }

    const now = timer.get()
    const current = now.year()

    const otherYear = now.month() > 5 ? current + 1 : current - 1

    const [resA, resB] = await Promise.all([
      this.getYearHoliday(current),
      this.getYearHoliday(otherYear),
    ])

    const newlist = [resA, resB]

    runInAction(() => {
      state.holiday_list = newlist
    })

    return newlist
  }

  async getHoliday(year) {
    const doc = this.toJS().doc
    if (doc.holiday_year === year) {
      return doc
    }

    const newDoc = await this.getYearHoliday(year)
    runInAction(() => {
      state.doc = newDoc
    })

    return newDoc
  }
}

export default new Holiday()
