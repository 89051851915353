import { useState, useEffect, useCallback, lazy, Suspense } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'
import MainLayout from 'layout'

import AccountApp from './AccountApp'
import UserApp from './UserApp'

const Home = lazy(() => import('pages/home'))
const ContactUs = lazy(() => import('pages/contact'))
const AboutUs = lazy(() => import('pages/about'))
const LoginPage = lazy(() => import('pages/login'))
const ForgetPassword = lazy(() => import('pages/password/forgetPassword'))
const ResetPassword = lazy(() => import('pages/password/resetPassword'))
const ForgetCompleted = lazy(() => import('pages/password/forgetCompleted'))
const ResetCompleted = lazy(() => import('pages/password/resetCompleted'))
const Consent = lazy(() => import('pages/consent'))
const NewJoiner = lazy(() => import('pages/newJoiner'))
const VerifyPhonePage = lazy(() => import('pages/phone/verify'))
const VerifyOtpPage = lazy(() => import('pages/phone/otp'))

const App = (props) => {
  const [loading, setLoading] = useState(true)

  const onCheck = useCallback(async () => {
    try {
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="*" element={<Loading />} />
      </Routes>
    )
  }

  return (
    <MainLayout {...props}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verify-phone" element={<VerifyPhonePage />} />
          <Route
            path="/verify-otp/:country_code/:mobile"
            element={<VerifyOtpPage />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route
            path="/reset-password/:ref_code/:code"
            element={<ResetPassword />}
          />
          <Route
            path="/forget-password/completed"
            element={<ForgetCompleted />}
          />
          <Route
            path="/reset-password/completed"
            element={<ResetCompleted />}
          />
          <Route path="/new-joiner/:ref_code/:code" element={<NewJoiner />} />
          <Route path="/consent/:typ" element={<Consent />} />

          <Route path="/account/*" element={<AccountApp />} />
          <Route path="/user/*" element={<UserApp />} />
        </Routes>
      </Suspense>
    </MainLayout>
  )
}

export default inject('member')(observer(App))
