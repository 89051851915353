import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Phnom_Penh')
/*
moment.updateLocale('en', {
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค',
  ],
  // weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  weekdaysMin: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
})
*/
export class Timer {
  get(init, format) {
    if (init && format) return moment(init, format)
    else if (init) return moment(init)

    return moment()
  }

  getDate() {
    const val = moment().startOf('day')
    return val
  }

  getNoMinute() {
    return moment().minute(0).second(0)
  }

  getUnix(init, format) {
    return this.get(init, format).unix()
  }

  getOnlyDate(val, format) {
    const date = moment(val, format).minute(0).second(0)
    date.set('hour', 0)
    date.set('minute', 0)
    date.set('second', 0)

    return date
  }

  format() {
    return moment().format()
  }

  toThatDate(val, time = false) {
    const date = moment(val)
    const text_date = date.format('DD/MM/') + `${date.year()}`

    const text_time = time ? ` ${date.format('HH:mm')}` : ''
    return text_date + text_time
  }

  convertToMoment(val, format) {
    try {
      if (val === '' || val === undefined || val === null) return undefined
      else if (moment.isMoment(val)) return val

      return moment(val, format)
    } catch (e) {
      console.log('to moment:', e.message)
    }

    return undefined
  }

  getDateNoHoliday(init = moment().startOf('day'), holiday_list = []) {
    let date = init.clone()
    for (let i = 0; i < 30; i++) {
      const result = this.isHoliday(date, holiday_list)
      if (result === false) return date

      date.add(1, 'days')
    }

    return date
  }

  isHoliday(current, holiday_list = []) {
    const year = current.year()
    const monthTxt = current.format('MMMM').toLowerCase()
    const dayOfWeek = current.weekday()
    const dateTxt = current.format('DD-MM-YYYY')

    let index = holiday_list.findIndex((it) => it.holiday_year === year)
    if (index < 0) return false

    const configHoliday = holiday_list[index].data
    if (configHoliday.saturday && dayOfWeek === 6) return true
    if (configHoliday.sunday && dayOfWeek === 0) return true

    const configMonth = configHoliday[monthTxt]
    index = configMonth.findIndex((item) => item.date === dateTxt)

    return index >= 0
  }
}

export const timer = new Timer()
export default timer
