const Logo = (props) => {
  const { size = 1, color = 'white' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(28 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7" height="28" rx="3.5" fill={color} />
      <rect x="13" width="7" height="28" rx="3.5" fill={color} />
    </svg>
  )
}

export default Logo
