import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

import { Close } from 'icon'
import { PrimaryBtn } from 'components/button'

import Camera from './Camera'

const UserDrawer = (props) => {
  const [photo, setPhoto] = useState('')
  const { text, loading, visible, onClose, onSave } = props

  useEffect(() => {
    if (visible) {
      setPhoto('')
    }
  }, [visible])

  const onChange = (item, isActive) => {}

  const onConfirm = () => onSave()

  return (
    <Modal
      footer=""
      open={visible}
      className="camera-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <Btn onClick={onClose}>
        <Close />
      </Btn>
      <Body>
        <ContentView>
          <Camera />
        </ContentView>
        <Button>
          <SaveButton>
            <PrimaryBtn
              loading={loading}
              text={text}
              disabled={!onSave}
              onClick={onConfirm}
            />
          </SaveButton>
        </Button>
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  position: relative;
  min-height: 100vh;

  ${(p) => p.theme.media.mobile} {
    min-height: 80vh;
  }
`

const ContentView = styled.div`
  padding-top: 48px;
  width: 100%;
`

const Btn = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`

const SaveButton = styled.div`
  width: 250px;
  margin: 0 auto;
`

const Button = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

export default UserDrawer
