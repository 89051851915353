import styled, { useTheme } from 'styled-components'
import { TimePicker as Picker } from 'antd'

import { Time } from 'icon'
import { timer } from 'utils'

import Invalid from './Invalid'

const TimePicker = (props) => {
  const theme = useTheme()
  const {
    label,
    disabled = false,
    value,
    clear = false,
    readonly = false,
    invalid,
    name,
    minTime,
    maxTime,
    tag,
    onChange = () => {},
  } = props

  const getValue = () => {
    if (typeof value === 'string' || value instanceof String) {
      return timer.get(value)
    }

    return value || timer.getOnlyDate()
  }

  const current = getValue()

  const onValue = (val) => {
    const dTxt = current.format('DD/MM/YYYY')
    const tTxt = val.format('HH:mm')

    const date = timer.get(`${dTxt} ${tTxt}`, 'DD/MM/YYYY HH:mm')
    onChange(date, name, tag)
  }

  const onDisabled = () => {
    const min = timer.get(minTime || '00:00', 'HH:mm')
    const max = timer.get(maxTime || '23:59', 'HH:mm')

    const minHour = min.hour()
    const maxHour = max.hour()
    const hoursList = HoursList.filter((val) => {
      return val < minHour || val > maxHour
    })
    return {
      disabledHours: () => hoursList,
    }
  }

  const css = current ? 'fill' : 'empty'
  const readonlyCss = readonly ? 'picker-readonly' : ''
  const invalidCss = invalid ? 'invalid' : ''

  const color = invalid ? theme.color.error : undefined
  return (
    <PageView className={`${css} ${readonlyCss} ${invalidCss}`}>
      <Label className={css}>{label}</Label>
      <Picker
        placeholder={label}
        disabled={disabled || readonly}
        allowClear={clear}
        showNow={false}
        disabledTime={onDisabled}
        minuteStep={5}
        className={css}
        value={current}
        onSelect={onValue}
        format="HH:mm"
        suffixIcon={<Time color={color} />}
      />
      <Invalid invalid={invalid} />
    </PageView>
  )
}

const HoursList = []
for (let i = 0; i < 24; i++) {
  HoursList.push(i)
}

const PageView = styled.div`
  position: relative;

  .ant-picker {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.color.border};
    padding: 4px 11px;
  }

  .invalid {
    border-color: ${(p) => p.theme.color.red};
  }

  .ant-picker-suffix {
    position: absolute;
    right: 0px;
  }

  .ant-picker > .ant-picker-input > input {
    font-size: 16px;
    color: ${(p) => p.theme.color_level.grey.high};
    padding-top: 14px;
  }

  .ant-picker > .ant-picker-input > input::placeholder {
    font-size: 16px;
    color: ${(p) => p.theme.color_level.grey.high};
  }

  &.empty .ant-picker .ant-picker-input input::placeholder {
    font-size: 14px;
    color: ${(p) => p.theme.color_level.grey.high};
  }

  &.empty .ant-picker > .ant-picker-input > input {
    padding-top: 0px;
  }

  &.invalid > .ant-picker > .ant-picker-input > input::placeholder {
    color: ${(p) => p.theme.color.error};
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
  position: absolute;
  left: 12px;
  top: 2px;
  z-index: 1;
  transition: all 0.3s ease;

  &.empty {
    display: none;
  }
`

export default TimePicker
