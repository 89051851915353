import { runInAction } from 'mobx'

import { format, http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Employee extends BaseStore {
  constructor() {
    super()
    this.observable({
      employee_list: [],
      menu_list: [],
    })
    state = this
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/user/account/employee/all`
    const resp = await http.get(url)

    const list = resp.body || []
    const employee_list = []
    const menus = list.map((it) => {
      const name = format.toUsername(it)
      it.username = name
      employee_list.push(it)
      return { name, value: it.user_id, tag: it }
    })

    runInAction(() => {
      state.employee_list = employee_list
      state.menu_list = menus
    })
  }
}

export default new Employee()
