import styled from 'styled-components'

import { Button } from 'components/display'

import Logo from './Logo'

const ErrorPage = (props) => {
  const { isError, text, title, detail, children, onClick } = props

  if (!isError) {
    return children
  }

  const h = detail ? <Detail>{detail}</Detail> : undefined
  return (
    <ContentView>
      <Logo />
      <Title>{title}</Title>
      {h}
      <Button text={text} onClick={onClick} />
    </ContentView>
  )
}

const ContentView = styled.div`
  width: 100%;
  min-height: 80vh;
  padding-top: 36px;
  text-align: center;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  padding-top: 16px;
`

const Detail = styled.div`
  width: 100%;
  font-size: 16px;
  margin-top: 24px;
`

export default ErrorPage
