import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

let state

const buildSummaryList = (member_list = [], summary_list = []) => {
  const list = member_list.map((it) => {
    const { user_id } = it
    const summary = summary_list.find((data) => data.user_id === user_id)
    return summary ? summary : undefined
  })

  return list
}

export class LeaveSummary extends BaseStore {
  constructor() {
    super()
    this.observable({
      my_config: {
        user_id: undefined,
        data: [],
      },
      doc: {
        project_id: undefined,
        year: 0,
        member_list: [],
        summary_list: [],
      },
    })

    state = this
  }

  async getLeaveConfig() {
    const { my_config = {} } = this.toJS()
    if (my_config.user_id) {
      return my_config
    }

    const url = `${config.api}/v1/user/account/my-config/leave-request`
    const res = await http.get(url)
    const doc = res.body

    runInAction(() => {
      state.my_config = doc
    })

    return doc
  }

  async updateLeaveConfig(member_list = []) {
    const json = { member_list }
    const url = `${config.api}/v1/user/account/my-config/leave-request`
    await http.put(url, { json })

    runInAction(() => {
      state.my_config.data = member_list
      state.doc = {
        project_id: undefined,
        year: 0,
        member_list: [],
        summary_list: [],
      }
    })
  }

  async getSummaryList({ member_list = [], project_id, year } = {}) {
    const { doc = {} } = this.toJS()

    if (doc.project_id === project_id && doc.year === year) {
      return
    }

    const ids = member_list.map((it) => it.user_id).join()
    const src = `${config.api}/v1/user/leave-request/leave-summary`
    const url =
      project_id === 'my-list'
        ? `${src}/my-list/${year}?ids=${ids}`
        : `${src}/team/${project_id}/${year}?ids=${ids}`

    const res = await http.get(url)

    const summary = res.body || {}

    const summary_list = buildSummaryList(member_list, summary.summary_list)
    runInAction(() => {
      state.doc = {
        project_id,
        year,
        member_list,
        summary_list,
      }
    })
  }
}

export default new LeaveSummary()
