import styled from 'styled-components'

const DisplayItem = (props) => {
  const { label, value = '' } = props

  return (
    <PageView>
      <Label>{label}</Label>
      <Value>{value === '' ? '-' : value}</Value>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Label = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.dark};
`

const Value = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  color: ${(p) => p.theme.color_level.grey.medium};
  padding-top: 3px;
`

export default DisplayItem
