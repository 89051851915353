import styled from 'styled-components'

const Label = (props) => {
  const { text } = props

  return <PageView>{text}</PageView>
}

const PageView = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
  padding-bottom: 16px;
`

export default Label
