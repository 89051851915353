import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { PhoneBtn, GmailBtn, TeamBtn } from 'components/button'
import { Line } from 'components/display'
import { message } from 'message'

const Provider = (props) => {
  const [loading, setLoading] = useState({})

  const navigate = useNavigate()

  const { lang } = props

  const onPhone = async () => {
    navigate('/verify-phone')
  }

  const onGoogle = async () => {
    try {
      setLoading({ gmail: true })
      await props.member.loginByGoogle()
      setLoading({})

      const pathname = window.location.pathname
      if (pathname === '/login') {
        navigate('/account')
      }
    } catch (e) {
      setLoading({})
      message.error(e.message)
    }
  }

  const onMicrosoft = async () => {
    try {
      setLoading({ microsoft: true })
      await props.member.loginByMircosoft()
      setLoading({})

      const pathname = window.location.pathname
      if (pathname === '/login') {
        navigate('/account')
      }
    } catch (e) {
      setLoading({})
      message.error(e.message)
    }
  }
  /*
  const onApple = async () => {
    try {
      setLoading({ apple: true })
      await props.member.loginByApple()
      setLoading({})

      const pathname = window.location.pathname
      if (pathname === '/login') {
        navigate('/account')
      }
    } catch (e) {
      setLoading({})
      message.error(e.message)
    }
  }
  */

  return (
    <PageView>
      <GmailBtn
        text={lang.getMessage('Login with Google', 'เข้าสู่ระบบด้วย Google')}
        loading={loading.gmail}
        disabled={loading.microsoft}
        onClick={onGoogle}
      />

      <Line />
      <PhoneBtn
        text={lang.getMessage(
          'Login with Mobile Number',
          'เข้าสู่ระบบด้วยเบอร์โทรศัพท์'
        )}
        disabled={loading.gmail || loading.microsoft}
        onClick={onPhone}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('member', 'lang')(observer(Provider))
/*
      <TeamBtn
        text={lang.getMessage(
          'Login with Microsoft',
          'เข้าสู่ระบบด้วย Microsoft'
        )}
        loading={loading.microsoft}
        disabled={loading.gmail}
        onClick={onMicrosoft}
      />
      <Line />
      <AppleBtn
        text={lang.getMessage('Login with Apple', 'เข้าสู่ระบบด้วย Apple')}
        loading={loading.apple}
        disabled={loading.gmail}
        onClick={onApple}
      />
*/
