import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import member from './Member'
import BaseStore from './BaseStore'

const original = {
  email: '',
  name: '',
  surname: '',
}

let state
export class Account extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
      emergency: {},
      address: {},
      doc: { file_list: [] },
      privacy: {},
      bank: {},
    })

    state = this
  }

  getUpdateUser(doc = {}, update_list = []) {
    const { user } = this.toJS()

    for (const name of update_list) {
      user[name] = doc[name]
    }
    return user
  }

  async getUserProfile() {
    const { user } = this.toJS()
    if (user.user_id) {
      return
    }
    const url = `${config.api}/v1/user/account/employee/full-detail`
    const res = await http.get(url)

    const data = res.body
    runInAction(() => {
      state.user = data
    })
  }

  async getGeneral() {
    const url = `${config.api}/v1/user/account/general`
    const res = await http.get(url)

    const { emergency, address } = res.body

    runInAction(() => {
      state.emergency = emergency
      state.address = address
    })
  }

  async getPrivacy() {
    const url = `${config.api}/v1/user/account/privacy`
    const res = await http.get(url)

    const { doc, privacy, bank } = res.body

    runInAction(() => {
      state.doc = doc
      state.privacy = privacy
      state.bank = bank
    })
  }

  async updateProfile(doc = {}, update_list = []) {
    const json =
      update_list.length === 0 ? doc : this.getUpdateUser(doc, update_list)

    const url = `${config.api}/v1/user/account/employee/detail`

    const res = await http.put(url, { json })
    const data = res.body || {}

    member.setProfile(data)
    runInAction(() => {
      state.user = data
    })
  }

  async updateEmergency(json = {}) {
    const url = `${config.api}/v1/user/account/general/emergency`
    const res = await http.put(url, { json })
    const data = res.body || {}

    runInAction(() => {
      state.emergency = data
    })
  }

  async updateAddress(json = {}) {
    const url = `${config.api}/v1/user/account/general/address`
    const res = await http.put(url, { json })
    const data = res.body || {}

    runInAction(() => {
      state.address = data
    })
  }
}

export default new Account()
