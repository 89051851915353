import { useState } from 'react'
import styled from 'styled-components'

import { BaseDialog } from 'dialog'
import { Find } from 'icon'

const Image = (props) => {
  const [visible, setVisible] = useState(false)
  const { img_url, height } = props

  const onOpen = () => setVisible(true)
  const onClose = () => setVisible(false)

  const content = img_url ? (
    <ImageView style={{ height }} src={img_url} />
  ) : undefined

  const btn = img_url ? (
    <Btn onClick={onOpen}>
      <Find />
    </Btn>
  ) : undefined
  return (
    <PageView>
      {content}
      {btn}
      <BaseDialog visible={visible} onClose={onClose}>
        <FullImageView src={img_url} />
      </BaseDialog>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: relative;
`

const ImageView = styled.img`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  object-fit: contain;
`

const FullImageView = styled.img`
  width: 100%;
  height: 80vh;
  border-radius: 8px;
  object-fit: contain;
`

const Btn = styled.span`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default Image
