import { Row, Col } from 'components/display'
import { DatePicker, Radio } from 'components/input'
import { timer } from 'utils'

const nextYear = timer.get().add(1, 'year')

const LeaveDate = (props) => {
  const {
    label,
    name,
    name_type,
    doc,
    menu,
    minDate,
    holiday_list = [],
    onChange,
  } = props

  const onDate = (val) => {
    doc[name] = val
    onChange(doc, name)
  }

  const onType = (val) => {
    doc[name_type] = val
    onChange(doc, name)
  }

  const onDateDisabled = (current) => {
    if (current) {
      if (minDate) {
        if (current <= minDate) return true
      }

      if (current > nextYear) return true

      return timer.isHoliday(current, holiday_list)
    }

    return false
  }

  return (
    <Row align="middle" padding="0px 0px 24px 0px">
      <Col {...col} position="left-responsive">
        <DatePicker
          label={label}
          name={name}
          value={doc[name]}
          onChange={onDate}
          onDateDisabled={onDateDisabled}
        />
      </Col>
      <Col {...col} position="right-responsive">
        <Radio
          name={name_type}
          value={doc[name_type]}
          menu={menu}
          onChange={onType}
        />
      </Col>
    </Row>
  )
}

const col = { lg: 12, md: 12, xs: 24 }

export default LeaveDate
