import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const attendance_url = `${config.api}/v1/user/attendance`

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'none',

  office_id: undefined,
  working_time_id: undefined,
  checkin_at: undefined,
  checkout_at: undefined,
}

let state

const toDate = (val) => {
  return val ? timer.get(val) : undefined
}

const toAttendance = (data = {}) => {
  data.work_start_at = toDate(data.work_start_at)
  data.work_end_at = toDate(data.work_end_at)

  data.check_start_at = toDate(data.check_start_at)
  data.check_end_at = toDate(data.check_end_at)

  data.check_break_start_at = toDate(data.check_break_start_at)
  data.check_break_end_at = toDate(data.check_break_end_at)

  data.checkin_at = toDate(data.checkin_at)
  data.checkout_at = toDate(data.checkout_at)
  data.checkin_break_at = toDate(data.checkin_break_at)
  data.checkout_break_at = toDate(data.checkout_break_at)
  data.break_start_at = toDate(data.break_start_at)
  data.break_end_at = toDate(data.break_end_at)
  return data
}

export class Request extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      setting: {
        gps_range: 1000,
        gps_status: 'no',
        beacon_status: 'no',
        is_require_photo: 'no',
      },
      office_list: [],
      attendance_list: [],
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  async check({ office_id, attendance_user_id, working_time_id }, json = {}) {
    const url = attendance_user_id
      ? `${attendance_url}/request/recheck/${attendance_user_id}/${office_id}`
      : `${attendance_url}/request/check/${working_time_id}/${office_id}`
    const res = await http.put(url, { json })
    const doc = toAttendance(res.body || {})

    runInAction(() => {
      state.doc = doc
    })

    return doc
  }

  async break({ office_id, attendance_user_id }, json = {}) {
    const url = `${attendance_url}/request/check-break/${attendance_user_id}/${office_id}`
    const res = await http.put(url, { json })
    const doc = res.body || {}

    runInAction(() => {
      state.doc = toAttendance(doc)
    })
  }

  async getAttendance() {
    const url = `${attendance_url}/request/available`
    const res = await http.get(url)
    const { attendance, office_list, setting } = res.body || {}

    const doc = toAttendance(attendance)
    runInAction(() => {
      state.doc = doc
      state.setting = setting
      state.office_list = office_list
    })
  }

  async getAttendancebyId(id) {
    const { attendance_user_id } = this.toJS().doc
    if (attendance_user_id === +id) {
      return
    }

    const url = `${attendance_url}/request/${id}/detail`
    const res = await http.get(url)
    const { attendance, office_list, setting } = res.body || {}

    const doc = toAttendance(attendance)

    runInAction(() => {
      state.doc = doc
      state.setting = setting
      state.office_list = office_list
    })
  }

  async getAttendanceList({ date } = {}) {
    const url = `${attendance_url}/report/${date.toISOString()}`
    const res = await http.get(url)
    const list = res.body || []

    const attendance_list = list.map((it) => toAttendance(it))
    runInAction(() => {
      state.attendance_list = attendance_list
    })
  }
}

export default new Request()
