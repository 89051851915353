import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

let state
export class LeaveType extends BaseStore {
  constructor() {
    super()
    this.observable({
      type_list: [],
    })

    state = this
  }

  async getList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().type_list.length > 0) {
        return
      }
    }
    const url = `${config.api}/v1/user/leave-request/leave-type`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.type_list = list
    })
  }
}

export default new LeaveType()
