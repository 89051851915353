import styled from 'styled-components'

const Status = (props) => {
  const { text, color, bg, max } = props

  return (
    <PageView style={{ color, backgroundColor: bg, maxWidth: max }}>
      {text}
    </PageView>
  )
}

const PageView = styled.div`
  width: fit-content;
  color: #3f3f3f;
  background-color: white;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  border-radius: 22px;
  text-align: center;
`

export default Status
