import store from 'stores'

// const timer = 2000
const error = (message = 'ขออภัยพบปัญหาบ้างอย่าง', { seconds } = {}) => {
  store.message.error({
    message,
    seconds,
  })
}

const warning = (message, { seconds } = {}) => {
  store.messager.warning({
    message,
    seconds,
  })
}

const success = (message = 'บันทึกสำเร็จ', { seconds } = {}) => {
  store.message.success({
    message,
    seconds,
  })
}

export const message = {
  error,
  warning,
  success,
}
