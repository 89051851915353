import styled from 'styled-components'

const Camera = (props) => {
  const {
    name = 'get file',
    label = 'Import',
    height = 90,
    onChange = () => {},
    value,
  } = props

  const h = `${height}px`
  const s = `${height - 24}px`

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onDelete = () => {
    onChange(undefined, name, undefined)
  }

  const onCh = async (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result, name, file)
    })
    reader.readAsDataURL(file)
  }

  return (
    <Section>
      <Video>
        <video id="video" width="320" height="240" autoPlay />
      </Video>
    </Section>
  )
}

/*
<Icon>
  <Edit color="#3F3F3F" size={0.8} />
</Icon>
*/

const Section = styled.div`
  font-size: 14px;

  width: 100%;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.green.high};
  background-color: white;
  border: 1px solid ${(p) => p.theme.color.border};
  border-radius: 8px;
  position: relative;

  &.disable {
    background-color: ${(p) => p.theme.color.disable};
  }
`

const Video = styled.div`
  width: 100%;
`

const Picker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
`

export default Camera
