/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(40 * size)}`
  const height = `${Math.ceil(40 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.3822 0H4.28577V40H22.1429H40.0001V13.5714L26.3822 0Z" fill="#CCCCCC"/>
      <path d="M0 17.8602V32.8574C0.022235 32.8574 0.0444701 32.8556 0.0667051 32.8556H32.8571V17.8574H0.0667051C0.0444701 17.8574 0.022235 17.8584 0 17.8593V17.8602Z" fill="#F36273"/>
      <path d="M26.4286 0L40 13.5714H29.3232C27.725 13.5714 26.4286 12.2759 26.4286 10.6769V0Z" fill="#626262"/>
      <path d="M5.58923 21.8172C5.58923 21.5737 5.78789 21.375 6.02069 21.375H8.48811C10.2364 21.375 11.387 22.3044 11.387 23.8316V23.8541C11.387 25.5251 9.99283 26.388 8.34429 26.388H6.46294V28.7341C6.46294 28.9777 6.26429 29.1763 6.02069 29.1763C5.7771 29.1763 5.58923 28.9768 5.58923 28.7341V21.819V21.8172ZM8.37755 25.5898C9.66114 25.5898 10.5016 24.904 10.5016 23.8856V23.8631C10.5016 22.7566 9.67193 22.1813 8.42159 22.1813H6.46294V25.5889H8.37665L8.37755 25.5898Z" fill="white"/>
      <path d="M12.9807 21.8172C12.9807 21.5737 13.1794 21.375 13.4122 21.375H15.6693C18.1034 21.375 19.7852 23.046 19.7852 25.2258V25.2483C19.7852 27.428 18.1034 29.1206 15.6693 29.1206H13.4122C13.1803 29.1206 12.9807 28.9211 12.9807 28.6784V21.8181V21.8172ZM15.6693 28.3125C17.6279 28.3125 18.8782 26.9849 18.8782 25.2698V25.2474C18.8782 23.5323 17.6279 22.1822 15.6693 22.1822H13.8544V28.3116H15.6693V28.3125Z" fill="white"/>
      <path d="M21.6333 21.8172C21.6333 21.5737 21.832 21.375 22.0648 21.375H26.8558C27.0769 21.375 27.254 21.5521 27.254 21.7732C27.254 21.9943 27.0769 22.1822 26.8558 22.1822H22.507V24.948H26.3578C26.5789 24.948 26.756 25.1251 26.756 25.3462C26.756 25.5674 26.5789 25.7444 26.3578 25.7444H22.507V28.7314C22.507 28.975 22.3084 29.1737 22.0648 29.1737C21.8212 29.1737 21.6333 28.9741 21.6333 28.7314V21.8163V21.8172Z" fill="white"/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
