import styled from 'styled-components'

import { PrimaryBtn, Btn } from 'components/button'

const FooterBtn = (props) => {
  const { loading, text = 'Save', close, onClick, onClose } = props

  const btnClose = onClose ? (
    <Button>
      <Btn text={close} onClick={onClose} size="md" />
    </Button>
  ) : undefined
  return (
    <PageView>
      {btnClose}
      <Button>
        <PrimaryBtn loading={loading} text={text} onClick={onClick} size="md" />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  justify-content: end;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
`

export default FooterBtn
