import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const reimburse_url = `${config.api}/v1/user/reimbursement/cost-center`
const DATE = 'YYYYMM'
let state
export class CostCenter extends BaseStore {
  constructor() {
    super()
    this.observable({
      balance_at: undefined,
      menu_list: [],
    })

    state = this
  }

  async getList({ balance_at = timer.get() } = {}) {
    const txt = balance_at.format(DATE)
    const store = this.toJS()

    const currentTxt = store.balance_at ? store.balance_at.format(DATE) : ''
    if (currentTxt === txt) {
      return
    }

    const url = `${reimburse_url}/${balance_at.toISOString()}`
    const res = await http.get(url)

    const cost_center_list = res.body || []

    const menu = cost_center_list.map((it) => {
      const tmp_list = it.category_list || []
      const category_list = tmp_list.map((cate) => {
        return {
          name: cate.name,
          value: cate.reimburse_category_id,
          tag: cate,
        }
      })
      return {
        name: it.cost_center_name,
        value: it.cost_center_id,
        tag: { category_list },
      }
    })

    runInAction(() => {
      state.balance_at = balance_at
      state.menu_list = menu
    })
  }

  reset() {
    runInAction(() => {
      state.balance_at = undefined
      state.menu_list = []
    })
  }
}

export default new CostCenter()
