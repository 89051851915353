import styled from 'styled-components'

import { Upload, Delete } from 'icon'

const ImgPicker = (props) => {
  const {
    name = 'get file',
    readonly = false,
    disabled = false,
    label = 'Import',
    height = 90,
    onChange = () => {},
    extAccept = '.jpg, .jpeg, .png',
    value,
    file_name = '',
    file_size = 0,
  } = props

  const h = `${height}px`
  const s = `${height - 24}px`

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onDelete = () => {
    onChange(undefined, name, undefined)
  }

  const onCh = async (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result, name, file)
    })
    reader.readAsDataURL(file)
  }

  const getFileSize = () => {
    if (file_size < 1000) {
      return `${file_size}b`
    }
    const val = Math.ceil(file_size / 1000)

    return `${val}kb`
  }
  const disable = readonly || disabled
  const css = disable ? 'disable' : ''

  const content = value ? (
    <ImagePicker style={{ height: h }}>
      <Image style={{ height: s, width: s }} src={value} />
      <Text style={{ width: `calc(100% - ${h} - 50px)` }}>
        <Name>{file_name}</Name>
        <Size>{getFileSize()}</Size>
      </Text>
      <span onClick={onDelete}>
        <Delete />
      </span>
    </ImagePicker>
  ) : (
    <label readOnly={disable} htmlFor={disable ? '' : name}>
      <Picker style={{ height: h }}>
        <Upload color="#00B377" />
        {label}
      </Picker>
    </label>
  )
  return (
    <Section className={css}>
      <input
        id={name}
        type="file"
        accept={extAccept}
        style={{ display: 'none' }}
        onChange={onCh}
        onClick={onClick}
      />
      {content}
    </Section>
  )
}

/*
<Icon>
  <Edit color="#3F3F3F" size={0.8} />
</Icon>
*/

const Section = styled.div`
  font-size: 14px;

  width: 100%;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.green.high};
  background-color: white;
  border: 1px solid ${(p) => p.theme.color.border};
  border-radius: 8px;
  position: relative;

  &.disable {
    background-color: ${(p) => p.theme.color.disable};
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 90px;
  }
`

const Picker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
`

const ImagePicker = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
`

const Text = styled.div`
  width: 80%;
`

const Name = styled.div`
  width: 100%;
  font-size: 14px;
  color: #1f1f1f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Size = styled.div`
  width: 100%;
  font-size: 14px;
  color: #626262;
`

const Image = styled.img`
  border: 1px solid ${(p) => p.theme.color.border};
  border-radius: 4px;
  object-fit: contain;
`

export default ImgPicker
