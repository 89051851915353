import styled from 'styled-components'

import Avatar from './Avatar'

const User = (props) => {
  const { name, img_url, size, fontSize, color, bg } = props

  return (
    <ViewContent>
      <Avatar
        name={name}
        img_url={img_url}
        size={size}
        fontSize={fontSize}
        color={color}
        bg={bg}
      />
      <Name>{name}</Name>
    </ViewContent>
  )
}

const ViewContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 12px;
`

const Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default User
