import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const original = {
  company_id: '',
  notification: {
    email: '',
    hr_email: '',
  },
  request: {
    submit_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
    cancel_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
  },
}

const getPastDate = (days) => {
  return timer.getDate().subtract(days || 0, 'days')
}

let state
export class ReimburseConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })

    state = this
  }

  async getConfig({ force = false } = {}) {
    const doc = this.toJS().doc
    if (force === false && doc.reimburse_config_id) {
      return
    }

    const url = `${config.api}/v1/user/reimbursement/config`
    const res = await http.get(url)

    const newDoc = res.body

    const { request = {} } = newDoc

    request.submit_disable.past_date = getPastDate(
      request.submit_disable.past_day
    )
    request.cancel_disable.past_date = getPastDate(
      request.cancel_disable.past_day
    )

    newDoc.request = request
    runInAction(() => {
      state.doc = newDoc
    })
  }
}

export default new ReimburseConfig()
