import styled from 'styled-components'

const Toggle = (props) => {
  const { name, value = false, tag, onChange = () => {} } = props

  const onValue = (evt) => {
    onChange(!value, name, tag)
  }

  return (
    <Switch>
      <Input type="checkbox" checked={value} onChange={onValue} />

      <span></span>
    </Switch>
  )
}

const Switch = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  margin: 0 auto;
  border-radius: 40px;

  span {
    display: block;
    width: 100%;
    height: 16px;
    background-color: ${(p) => p.theme.color.disable};
    border-radius: 20px;
    transition: 0.8s ease background-color;
  }

  span:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0px;
    width: 32px;
    height: 32px;
    background-color: ${(p) => p.theme.color_level.grey.low};
    border-radius: 50%;
    transform: translate(-5px, 0px);
    transition: 0.8s ease transform, 0.6s box-shadow;
  }

  input[type='checkbox']:checked + span:before {
    transform: translate(100%, 0px);
    left: calc(100% - 64px);
    background-color: ${(p) => p.theme.color_level.green.medium};
  }

  input[type='checkbox']:checked + span {
    background-color: ${(p) => p.theme.color_level.green.low};
  }
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
`

export default Toggle
