import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Link } from 'components/link'
import { PrimaryBtn } from 'components/button'
import { Line } from 'components/display'
import { Input, InputPassword } from 'components/input'
import { validator } from 'utils'
import { message } from 'message'

import Provider from './Provider'
import rules from './rules'

const LoginPage = (props) => {
  const [doc, setDoc] = useState({})
  const [loading, setLoading] = useState({})
  const [validate, setValidate] = useState({})

  const navigate = useNavigate()

  const { lang } = props

  const onCh = (val, name) => {
    doc[name] = val

    setDoc({ ...doc })

    delete validate[name]
    setValidate({ ...validate })
  }

  const onEmail = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading({ email: true })
      await props.member.loginByEmail(doc)
      setLoading({})
      setDoc({})

      const pathname = window.location.pathname
      if (pathname === '/login') {
        navigate('/account')
      }
    } catch (e) {
      setLoading({})
      message.error(e.message)
    }
  }

  return (
    <PageView>
      <Title>{lang.getMessage('Welcome to HLO', 'ยินดีต้อนรับสู่ฮาโล')}</Title>
      <Line>
        <Input
          label={lang.getMessage('Email', 'อีเมล')}
          name="email"
          value={doc.email}
          onChange={onCh}
          invalid={lang.getValidate(validate['email'], 'email', 'อีเมล')}
        />
      </Line>

      <Line>
        <InputPassword
          label={lang.getMessage('Password', 'รหัสผ่าน')}
          name="password"
          value={doc.password}
          onChange={onCh}
          invalid={lang.getValidate(
            validate['password'],
            'password',
            'รหัสผ่าน'
          )}
        />
      </Line>
      <PrimaryBtn
        text={lang.getMessage('Login', 'เข้าสู่ระบบ')}
        loading={loading.email}
        disabled={loading.gmail}
        onClick={onEmail}
      />
      <Line />

      <Forget>
        <Link to="/forget-password">
          {lang.getMessage('Forget password?', 'ลืมรหัสผ่าน')}
        </Link>
      </Forget>
      <Or>{lang.getMessage('OR', 'หรือ')}</Or>
      <Provider />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 24px 20px;
  background-color: white;
  border-radius: 40px;

  ${(p) => p.theme.media.mobile} {
    padding: 30px 0px;
    background-color: transparent;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;

  ${(p) => p.theme.media.mobile} {
  }
`

const Or = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin: 16px 0px;
`

const Forget = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  text-align: right;
`

/*
<Register>
  {lang.getMessage('Don’t have an account?', 'ไม่เป็นสมาชิก')}
  <Link to="/register">{lang.getMessage('Sign up', 'สมัคร')}</Link>
</Register>

const Register = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
  a {
    font-weight: 700;
    margin-left: 8px;
  }
`
*/
export default inject('member', 'lang')(observer(LoginPage))
