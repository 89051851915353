import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const overtime_url = `${config.api}/v1/user/overtime`

const original = {
  ot_category_id: undefined,
  start_at: undefined,
  finish_at: undefined,
  note: '',
}

let state
export class Request extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      list: [],
    })

    state = this
  }

  reset({ category_menu = [] } = {}) {
    const doc = cloneDeep(original)
    doc.ot_category_id =
      category_menu.length === 1 ? category_menu[0].value : undefined
    runInAction(() => {
      state.doc = doc
    })
  }

  setDoc(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }

  async submitRequest(json = {}) {
    const url = `${overtime_url}/request`
    await http.post(url, { json })

    const doc = cloneDeep(original)
    runInAction(() => {
      state.doc = doc
    })
  }

  async cancelRequest({ ot_req_id, reason } = {}) {
    const url = `${overtime_url}/request/${ot_req_id}/cancel`
    const res = await http.put(url, { json: { reason } })
    const { list = [] } = this.toJS()

    const i = list.findIndex((it) => it.ot_req_id === ot_req_id)
    if (i !== -1) {
      const newDoc = res.body
      list[i] = newDoc
      runInAction(() => {
        state.list = list
      })
    }
  }

  async getList({ date } = {}) {
    const url = `${overtime_url}/report/${date.toISOString()}`
    const res = await http.get(url)
    const list = res.body || []

    runInAction(() => {
      state.list = list
    })
  }
}

export default new Request()
