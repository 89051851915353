import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const reimburse_url = `${config.api}/v1/user/reimbursement`

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'draft',

  cost_center_id: undefined,
  reimburse_category_id: undefined,
  amount: 0,
  receipt_at: timer.get(),

  file_list: [],
  created_at: undefined,
  updated_at: undefined,
}

let state
export class Request extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      reimburse_category: {},
      reimburse_list: [],
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  setDoc(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }

  setReimburseCategory(doc) {
    runInAction(() => {
      state.reimburse_category = doc
    })
  }

  async submitRequest(json = {}) {
    const url = `${reimburse_url}/request`
    await http.post(url, { json })

    const doc = cloneDeep(original)
    doc.receipt_at = undefined
    runInAction(() => {
      state.doc = doc
    })
  }

  async cancelRequest({ reimburse_request_id, reason } = {}) {
    const url = `${reimburse_url}/request/${reimburse_request_id}/cancel`
    const res = await http.put(url, { json: { reason } })
    const { reimburse_list = [] } = this.toJS()

    const i = reimburse_list.findIndex(
      (it) => it.reimburse_request_id === reimburse_request_id
    )
    if (i !== -1) {
      const newDoc = res.body
      const oldDoc = reimburse_list[i]
      reimburse_list[i] = { ...oldDoc, ...newDoc }
      runInAction(() => {
        state.reimburse_list = reimburse_list
      })
    }
  }

  async getReimbursementList({ date }) {
    const url = `${reimburse_url}/report/${date.toISOString()}`
    const res = await http.get(url)
    const list = res.body || []

    runInAction(() => {
      state.reimburse_list = list
    })
  }
}

export default new Request()
