import styled from 'styled-components'

const Information = (props) => {
  const { text } = props

  const css = text ? 'information' : ''

  return <View className={css}>{text || ''}</View>
}

const View = styled.div`
  color: ${(p) => p.theme.color.grey};
  font-size: 14px;
  text-align: left;
  display: none;

  &.information {
    margin-top: 4px;
    display: block;
  }
`

export default Information
