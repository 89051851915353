/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#626262' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.74228e-08 10C3.91405e-08 9.44772 0.447715 9 1 9L19 9C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11L1 11C0.447715 11 1.35705e-07 10.5523 8.74228e-08 10Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.29289 19.7071C8.90237 19.3166 8.90237 18.6834 9.29289 18.2929L17.5858 10L9.29289 1.70711C8.90237 1.31658 8.90237 0.683417 9.29289 0.292893Z" fill={color}/>
    </svg>

  )
}
/* eslint-enable */
export default Logo
