import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const UserUrl = `${config.api}/v1/user/account`
const LeaveReqUrl = `${config.api}/v1/user/leave-request/leave`
const ReimburseReqUrl = `${config.api}/v1/user/reimbursement/request`
const OTReqUrl = `${config.api}/v1/user/overtime/request`

let state

const isSameRequest = ({ request_type, id }, data) => {
  if (request_type !== data.request_type) {
    return false
  }

  const {
    leave_req_data = {},
    reimbursement_data = {},
    overtime_data = {},
  } = data
  let current_id
  switch (request_type) {
    case 'reimbursement':
      current_id = reimbursement_data.reimburse_request_id
      break
    case 'overtime':
      current_id = overtime_data.ot_req_id
      break
    default:
      current_id = leave_req_data.leave_request_id
      break
  }

  return id === current_id
}

export class Task extends BaseStore {
  constructor() {
    super()
    this.observable({
      my_request: {
        list: [],
        total: 0,
      },
      team_request: {
        list: [],
        total: 0,
      },
      history: {
        list: [],
        total: 0,
      },
    })
    state = this
  }

  async getMyRequestList() {
    const url = `${UserUrl}/my-task/my-request`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.my_request = {
        list,
        total: list.length,
      }
    })
  }

  async getTeamRequestList() {
    const url = `${UserUrl}/my-task/team-request`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.team_request = {
        list,
        total: list.length,
      }
    })
  }

  async getHistoryList() {
    const url = `${UserUrl}/my-task/history`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.history = {
        list,
        total: list.length,
      }
    })
  }

  updateMyRequest({ request_type, id }, data) {
    const { my_request } = this.toJS()
    const list = my_request.list || []
    const index = list.findIndex((it) => {
      return isSameRequest({ request_type, id }, it)
    })

    if (index !== -1) {
      list.splice(index, 1)

      runInAction(() => {
        state.my_request = {
          list,
          total: list.length,
        }
      })
    }
  }

  updateTeamRequest({ request_type, id }, data) {
    const { team_request } = this.toJS()
    const list = team_request.list || []
    const index = list.findIndex((it) => {
      return isSameRequest({ request_type, id }, it)
    })

    if (index !== -1) {
      list.splice(index, 1)

      runInAction(() => {
        state.team_request = {
          list,
          total: list.length,
        }
      })
    }
  }

  // leave request
  async approveLeaveReq({ leave_request_id }) {
    const url = `${LeaveReqUrl}/${leave_request_id}/approve`
    await http.put(url)

    const request_type = 'leave_request'
    const id = leave_request_id
    this.updateTeamRequest({ request_type, id })
  }

  async rejectLeaveReq({ leave_request_id, reason }) {
    const url = `${LeaveReqUrl}/${leave_request_id}/reject`
    await http.put(url, { json: { reason } })

    const request_type = 'leave_request'
    const id = leave_request_id
    this.updateTeamRequest({ request_type, id })
  }

  async cancelLeaveReq({ leave_request_id, reason } = {}) {
    const url = `${LeaveReqUrl}/${leave_request_id}/cancel`
    await http.put(url, { json: { reason } })

    const request_type = 'leave_request'
    const id = leave_request_id
    this.updateMyRequest({ request_type, id })
  }

  // reimburse
  async approveReimburseReq({ reimburse_request_id }) {
    const url = `${ReimburseReqUrl}/${reimburse_request_id}/approve`
    await http.put(url)

    const request_type = 'reimbursement'
    const id = reimburse_request_id
    this.updateTeamRequest({ request_type, id })
  }

  async rejectReimburseReq({ reimburse_request_id, reason }) {
    const url = `${ReimburseReqUrl}/${reimburse_request_id}/reject`
    await http.put(url, { json: { reason } })

    const request_type = 'reimbursement'
    const id = reimburse_request_id
    this.updateTeamRequest({ request_type, id })
  }

  async cancelReimburseReq({ reimburse_request_id, reason } = {}) {
    const url = `${ReimburseReqUrl}/${reimburse_request_id}/cancel`
    await http.put(url, { json: { reason } })

    const request_type = 'reimbursement'
    const id = reimburse_request_id
    this.updateMyRequest({ request_type, id })
  }

  // overtime
  async approveOvertimeReq({ ot_req_id }) {
    const url = `${OTReqUrl}/${ot_req_id}/approve`
    await http.put(url)

    const request_type = 'overtime'
    const id = ot_req_id
    this.updateTeamRequest({ request_type, id })
  }

  async rejectOvertimeReq({ ot_req_id, reason }) {
    const url = `${OTReqUrl}/${ot_req_id}/reject`
    await http.put(url, { json: { reason } })

    const request_type = 'overtime'
    const id = ot_req_id
    this.updateTeamRequest({ request_type, id })
  }

  async cancelOvertimeReq({ ot_req_id, reason } = {}) {
    const url = `${OTReqUrl}/${ot_req_id}/cancel`
    await http.put(url, { json: { reason } })

    const request_type = 'overtime'
    const id = ot_req_id
    this.updateMyRequest({ request_type, id })
  }
}

export default new Task()
