export const sleep = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const isNull = (val) => {
  return val === null || val === undefined
}

export const notNull = (val) => {
  return val !== null && val !== undefined
}

export const isValue = (val) => {
  return val !== null && val !== undefined && val !== ''
}

export const isTextError = (val, text) => {
  return val === '' || val === null ? text : undefined
}

export const random = ({
  len,
  prefix = '',
  postfix = '',
  character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
}) => {
  let need = len - (prefix.length + postfix.length)
  let max = character.length
  if (need < 0) return
  if (max === 0) return
  let resp = ''

  for (let i = 0; i < need; i++) {
    resp += character.charAt(Math.floor(Math.random() * max))
  }
  return `${prefix}${resp}${postfix}`
}

export const isUrl = (val) => {
  if (isValue(val)) {
    let prefix = val.substring(0, 4)
    return prefix === 'http'
  }

  return false
}

export const trim = (data, list = []) => {
  list.forEach((name) => {
    let temp = data[name]
    if (temp) data[name] = temp.trim()
  })

  return data
}

export const compareString = (src, target) => {
  return String(src).toLocaleLowerCase() === String(target).toLocaleLowerCase()
}

export const getUrl = (list = []) => {
  const img_url = list.length > 0 ? list[0] : undefined
  return img_url
}

export const initMenu = (list = []) => {
  const keys = {}
  const menu = []
  const menu_th = []
  for (const item of list) {
    const { name, name_th, value } = item

    keys[value] = item
    menu_th.push({ name: name_th, value })
    menu.push({ name, value })
  }

  return { keys, menu, menu_th }
}

const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

export const getDistanceInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)

  const aa = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  const bb = Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
  const cc = Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const a = aa + bb * cc
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c * 1000 // Distance in meter

  return d
}
