import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PrimaryBtn, Btn } from 'components/button'
import { InputArea } from 'components/input'

import BaseDialog from './BaseDialog'

const ConfirmTextDialog = (props) => {
  const [text, setText] = useState('')
  const { visible, title, cancel, confirm, onClose, onConfirm } = props

  useEffect(() => {
    if (visible) {
      setText('')
    }
  }, [visible])

  const onChange = (val) => setText(val)
  const onSave = () => onConfirm(text)

  return (
    <BaseDialog visible={visible} onClose={onClose}>
      <Content>
        <Title>{title}</Title>
        <Message>
          <InputArea value={text} onChange={onChange} />
        </Message>
        <Footer>
          <Row>
            <Col lg={9} xs={24}>
              <Cancel>
                <Btn text={cancel} onClick={onClose} />
              </Cancel>
            </Col>
            <Col lg={15} xs={24}>
              <Confirm>
                <PrimaryBtn text={confirm} onClick={onSave} />
              </Confirm>
            </Col>
          </Row>
        </Footer>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 320px;

  @media screen and (max-width: 320px) {
    min-width: 280px;
  }
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.color.black};
`

const Message = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.color_level.grey.high};
`

const Cancel = styled.div`
  padding-right: 8px;
`

const Confirm = styled.div`
  padding-left: 8px;
`

const Footer = styled.div`
  width: 100%;
`

export default ConfirmTextDialog
