import styled from 'styled-components'

import Cookie from 'components/cookie'
import Message from 'layout/Message'

const MainLayout = (props) => {
  const { children } = props

  return (
    <>
      <Message />
      <Wrapper>
        <Body>{children}</Body>
        <Cookie {...props} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  color: ${(p) => p.theme.color.black};
  background-color: ${(p) => p.theme.color.backgroundColor};
`

const Body = styled.div`
  width: 100%;
  min-height: 85vh;
  max-width: ${(p) => p.theme.max};

  @media print {
    min-height: 90vh;
    margin-top: 0px;
    padding: 0px;
  }
`

export default MainLayout
