import { runInAction } from 'mobx'

import { http, timer, format } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user/payroll`

const addItemToList = (
  label,
  label_th,
  val,
  { label_list, label_th_list, list }
) => {
  if (val > 0) {
    const valTxt = format.toMoney(val)
    label_list.push(`${label}: ${valTxt}`)
    label_th_list.push(`${label_th}: ${valTxt}`)
    list.push(val)
  }
}

let state
export class Payroll extends BaseStore {
  constructor() {
    super()
    this.observable({
      tvi50: {},
      doc: {
        chart: {},
        summary: {},
        current: {},
      },
      history_list: [],
    })

    state = this
  }

  async getPayroll({ payroll_id }) {
    const url = `${Url}/report/${payroll_id}/detail`
    const res = await http.get(url)

    const { summary = {}, current = {} } = res.body || {}

    const total_deduction = summary.total_deduction || 0
    const wht = summary.wht || 0
    const ssf = summary.ssf || 0
    const provident_fund = summary.provident_fund || 0
    const total = summary.total || 0

    const deduction = total_deduction - wht - ssf - provident_fund
    const label_list = []
    const label_th_list = []
    const list = []

    addItemToList('Other', 'อื่นๆ', deduction, {
      label_list,
      label_th_list,
      list,
    })

    addItemToList('Tax', 'ภาษี', wht, {
      label_list,
      label_th_list,
      list,
    })

    addItemToList('Ssf', 'ประกันสังคม', ssf, {
      label_list,
      label_th_list,
      list,
    })

    addItemToList('Provident fund', 'กองทุนสำรองเลี้ยงชีพ', provident_fund, {
      label_list,
      label_th_list,
      list,
    })

    addItemToList('Net pay', 'จ่ายสุทธิ', total, {
      label_list,
      label_th_list,
      list,
    })

    runInAction(() => {
      state.doc = {
        chart: {
          code: timer.getUnix(),
          label_list,
          label_th_list,
          list,
        },
        summary,
        current,
      }
    })
  }

  async getHistoryList({ date } = {}) {
    const s = date.toISOString()

    const url = `${Url}/report/${s}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.history_list = list
    })
  }

  async getPayslip({ payroll_user_id, language = 'en' } = {}) {
    const url = `${Url}/report/${payroll_user_id}/${language}/payslip`
    const res = await http.get(url)

    const { payslip_url } = res.body
    return payslip_url
  }

  async getTvi50({ date } = {}) {
    const payroll_year = date.year()

    const tvi50 = this.toJS().tvi50 || {}
    if (tvi50.payroll_year === payroll_year) {
      return
    }

    const url = `${Url}/report/tvi50/${payroll_year}`
    const res = await http.get(url)

    const doc = res.body || {}
    runInAction(() => {
      state.tvi50 = doc
    })
  }

  async getTvi50Document({ tvi50_user_id } = {}) {
    const url = `${Url}/report/tvi50/${tvi50_user_id}/pdf`
    const res = await http.get(url)

    const { file_url } = res.body
    return file_url
  }
}

export default new Payroll()
