/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color="#626262" } = props

  const width = `${Math.ceil(18 * size)}`
  const height = `${Math.ceil(14 * size)}`
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.125 7.125L6.375 12.375L16.875 1.125" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
