import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

import { Meta, BackHeader, Layout } from 'components/display'
import { ErrorPage } from 'components/error'
import { PrimaryBtn } from 'components/button'
import { message } from 'message'
import { validator } from 'utils'

import Category from './Category'
import Information from './Information'

const LeaveSubmit = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [err, setErr] = useState(undefined)
  const [validate, setValidate] = useState({})

  const navigate = useNavigate()
  const location = useLocation()

  const { lang } = props
  const { doc, available_day } = props.leaveReq.toJS()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/account'
    navigate(path)
  }

  const onValidate = (val) => setValidate({ ...val })

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      setErr(undefined)
      const [holiday_list] = await Promise.all([
        props.holiday.getHolidayList(),
        props.leaveType.getList(),
        props.leaveConfig.getConfig(),
        props.leaveBalance.getLeaveBalance(),
      ])

      props.leaveReq.reset(holiday_list)
    } catch (e) {
      setErr(e.message)
    }

    setLoading(false)
  }, [
    props.holiday,
    props.leaveReq,
    props.leaveType,
    props.leaveConfig,
    props.leaveBalance,
  ])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onSave = async () => {
    try {
      const invalid = {}
      if (!doc.leave_type_id) {
        invalid.leave_type_id = true
      }

      if (available_day !== undefined && available_day < doc.leave_day) {
        invalid.available_day = true
      }

      if (validator.isError(invalid)) {
        setValidate(invalid)
        return
      }
      setSaving(true)

      await props.leaveReq.submitLeaveRequest(doc)
      setSaving(false)

      navigate('/account/leave-req/completed')
    } catch (e) {
      if (e.message === 'Balance not enough') {
        setValidate({ available_day: true })
      } else {
        message.error(e.message, { seconds: 10 })
      }

      setSaving(false)
    }
  }

  const title = lang.getMessage('Leave request', 'ส่งใบลา')
  return (
    <PageView>
      <Meta title={title} description={title} />
      <BackHeader title={title} onBack={onBack} loading={loading}>
        <ErrorPage
          isError={err !== undefined}
          title={lang.getMessage('Opp! something wrong', 'เกิดความผิดพลาด')}
          text={lang.getMessage('Retry', 'ลองอีกครั้ง')}
          detail={err}
          onClick={onLoad}
        >
          <Body>
            <Category validate={validate} onValidate={onValidate} />
            <Information validate={validate} onValidate={onValidate} />
            <Button>
              <PrimaryBtn
                loading={saving}
                disabled={!doc.leave_category || validator.isError(validate)}
                text={lang.getMessage('Confirm', 'ยืนยัน')}
                onClick={onSave}
              />
            </Button>
          </Body>
        </ErrorPage>
      </BackHeader>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Body = styled.div`
  min-height: 100vh;
`

const Button = styled.div`
  padding: 24px 0px;
  width: 100%;
  max-width: 324px;
  margin: 0 auto;
`

export default inject(
  'lang',
  'holiday',
  'leaveReq',
  'leaveType',
  'leaveConfig',
  'leaveBalance'
)(observer(LeaveSubmit))
