import { Google } from 'icon/brand'

import IconBtn from './IconBtn'

const Btn = (props) => {
  const style = { left: '-4px' }
  return <IconBtn icon={<Google size={1.2} style={style} />} {...props} />
}

export default Btn
