import styled from 'styled-components'

const Status = (props) => {
  const { text, color, bg, max } = props

  return (
    <PageView style={{ color, backgroundColor: bg, maxWidth: max }}>
      {text}
    </PageView>
  )
}

const PageView = styled.div`
  width: fit-content;
  color: #626262;
  background-color: rgb(204, 204, 204, 0.4);
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 22px;
  text-align: center;
`

export default Status
