import { useState } from 'react'
import styled from 'styled-components'

const Avatar = (props) => {
  const [isErr, setErr] = useState(false)
  const { name, img_url, size, fontSize, color, bg } = props

  const txt = name || ''
  const n = txt.length === 0 ? 'H' : txt[0].toUpperCase()

  const onLoad = () => setErr(false)
  const onError = () => setErr(true)

  const css = {
    minWidth: size,
    width: size,
    height: size,
    fontSize,
    color,
    backgroundColor: bg,
  }
  return img_url && !isErr ? (
    <Image style={css} src={img_url} alt="" onLoad={onLoad} onError={onError} />
  ) : (
    <Name style={css}>{n}</Name>
  )
}

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`

const Name = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.color.border};
`

export default Avatar
