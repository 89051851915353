/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = 'color' } = props

  const c = color === 'white' ? white : colorFull
  const width = `${Math.ceil(103 * size)}`
  const height = `${Math.ceil(48 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 103 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6_18)">
        <path d="M36.6221 7.99997C32.3728 7.99997 28.2975 9.68568 25.2928 12.6863C22.288 15.6868 20.6 19.7565 20.6 24V25.1432H13.7338V16C13.7338 16.9005 13.5561 17.7923 13.211 18.6243C12.8658 19.4562 12.36 20.2122 11.7222 20.8489C11.0845 21.4856 10.3274 21.9907 9.49423 22.3352C8.66103 22.6797 7.76803 22.8569 6.86623 22.8567C5.0452 22.8567 3.29874 22.1343 2.01107 20.8484C0.723403 19.5626 0 17.8185 0 16V41.1432C0 42.0437 0.177602 42.9353 0.522663 43.7672C0.867723 44.5991 1.37348 45.355 2.01107 45.9917C2.64866 46.6284 3.40559 47.1335 4.23864 47.478C5.07169 47.8226 5.96455 48 6.86623 48C7.76803 48.0002 8.66103 47.8229 9.49423 47.4784C10.3274 47.1339 11.0845 46.6289 11.7222 45.9921C12.36 45.3554 12.8658 44.5995 13.211 43.7675C13.5561 42.9355 13.7338 42.0438 13.7338 41.1432V34.2851H20.6V41.1432C20.6 42.9617 21.3234 44.7058 22.6111 45.9917C23.8988 47.2776 25.6452 48 27.4662 48C28.368 48.0002 29.261 47.8229 30.0942 47.4784C30.9274 47.1339 31.6845 46.6289 32.3223 45.9921C32.96 45.3554 33.4659 44.5995 33.811 43.7675C34.1561 42.9355 34.3338 42.0438 34.3338 41.1432V24C34.3336 23.6998 34.3927 23.4025 34.5076 23.1251C34.6225 22.8477 34.791 22.5956 35.0036 22.3833C35.2161 22.1709 35.4684 22.0025 35.7461 21.8876C36.0238 21.7727 36.3215 21.7135 36.6221 21.7135C37.2293 21.7135 37.8117 21.9544 38.2411 22.3832C38.6705 22.812 38.9117 23.3936 38.9117 24C38.9115 23.0994 39.089 22.2077 39.434 21.3756C39.779 20.5435 40.2847 19.7875 40.9223 19.1507C41.5599 18.5138 42.3169 18.0086 43.15 17.664C43.9832 17.3193 44.8761 17.1419 45.7779 17.1419C46.6797 17.1419 47.5727 17.3193 48.4058 17.664C49.239 18.0086 49.996 18.5138 50.6336 19.1507C51.2712 19.7875 51.7769 20.5435 52.1219 21.3756C52.4669 22.2077 52.6443 23.0994 52.6442 24C52.6442 19.7565 50.9561 15.6868 47.9514 12.6863C44.9467 9.68568 40.8714 7.99997 36.6221 7.99997Z" fill={c.h}/>
        <path d="M2.49301 10.7077C1.98376 11.1281 1.53749 11.6192 1.16779 12.1661C1.53749 11.6192 1.98376 11.1281 2.49301 10.7077Z" fill={c.h2}/>
        <path d="M89.2662 16C89.2662 15.0994 89.4439 14.2076 89.789 13.3755C90.1341 12.5435 90.64 11.7874 91.2777 11.1506C91.9154 10.5138 92.6725 10.0086 93.5057 9.66394C94.3389 9.31929 95.2319 9.14191 96.1338 9.14191H73.2442C64.3954 9.14191 57.2221 15.3553 57.2221 23.0205V34.1227C57.2221 34.1777 57.2221 34.2314 57.2221 34.2851H61.8C62.7018 34.2851 63.5948 34.4625 64.4279 34.8072C65.261 35.1519 66.018 35.657 66.6557 36.2939C67.2933 36.9307 67.799 37.6868 68.144 38.5188C68.489 39.3509 68.6664 40.2427 68.6662 41.1432C68.6664 42.1789 68.4312 43.2012 67.9782 44.1329C67.5252 45.0646 66.8663 45.8815 66.0512 46.522C68.3214 47.504 70.7702 48.0071 73.2442 48C82.093 48 89.2662 41.7866 89.2662 34.1227V23.0205C89.2662 22.9655 89.2662 22.9118 89.2662 22.8567H96.1377C95.2356 22.8574 94.3422 22.6806 93.5085 22.3363C92.6748 21.992 91.9173 21.4871 91.2792 20.8503C90.641 20.2135 90.1348 19.4574 89.7894 18.6252C89.444 17.7929 89.2662 16.9009 89.2662 16ZM75.5338 34.2851C75.5338 34.8915 75.2926 35.4731 74.8632 35.9019C74.4338 36.3307 73.8514 36.5716 73.2442 36.5716C72.9436 36.5716 72.6459 36.5124 72.3682 36.3975C72.0905 36.2826 71.8381 36.1142 71.6256 35.9018C71.4131 35.6895 71.2446 35.4374 71.1297 35.16C71.0147 34.8826 70.9557 34.5853 70.9559 34.2851V22.8567C70.9559 22.2507 71.197 21.6694 71.6261 21.2409C72.0552 20.8124 72.6373 20.5716 73.2442 20.5716C73.5448 20.5714 73.8425 20.6304 74.1203 20.7452C74.3981 20.8599 74.6505 21.0282 74.8631 21.2404C75.0757 21.4527 75.2444 21.7046 75.3595 21.982C75.4746 22.2593 75.5338 22.5565 75.5338 22.8567V34.2851Z" fill={c.o}/>
        <path d="M6.86621 22.8568C7.768 22.8569 8.661 22.6797 9.4942 22.3352C10.3274 21.9907 11.0845 21.4856 11.7222 20.8489C12.3599 20.2122 12.8658 19.4563 13.211 18.6243C13.5561 17.7923 13.7337 16.9005 13.7337 16C13.7337 14.1811 13.0102 12.4367 11.7223 11.1506C10.4344 9.86445 8.68759 9.14191 6.86621 9.14191C6.40165 9.14174 5.93828 9.18871 5.48324 9.28211C5.04116 9.36943 4.60904 9.50113 4.19345 9.6752C3.57963 9.93547 3.00684 10.2833 2.49297 10.7077C1.98372 11.1281 1.53745 11.6192 1.16775 12.1661C0.402447 13.2983 -0.00441416 14.634 -2.59083e-05 16C-2.59083e-05 17.8185 0.723376 19.5626 2.01104 20.8485C3.29871 22.1344 5.04517 22.8568 6.86621 22.8568Z" fill={c.hc}/>
        <path d="M54.9338 41.1432C54.9332 40.1483 55.15 39.1651 55.569 38.2624C55.988 37.3597 56.5992 36.5591 57.3598 35.9165C58.5983 34.8624 60.1727 34.284 61.8 34.2852H52.6441L52.6441 24.7172C52.643 24.2958 52.6043 23.8753 52.5287 23.4607C52.2425 25.0407 51.4099 26.4703 50.1762 27.5C48.9424 28.5297 47.3858 29.0942 45.7779 29.0949C44.1566 29.0941 42.5879 28.5201 41.3498 27.4746C40.1118 26.4292 39.2843 24.9798 39.014 23.3834C38.9486 23.7599 38.9144 24.1412 38.9117 24.5233L38.9117 34.2852C38.9115 36.0862 39.2666 37.8696 39.9566 39.5335C40.6467 41.1975 41.6582 42.7094 42.9334 43.9829C44.2086 45.2565 45.7225 46.2668 47.3887 46.956C49.0548 47.6453 50.8407 48 52.6441 48H61.8C59.9789 48 58.2325 47.2776 56.9448 45.9917C55.6572 44.7058 54.9338 42.9618 54.9338 41.1432Z" fill={c.l}/>
        <path d="M45.7779 16C44.8761 16 43.9831 16.1774 43.15 16.5221C42.3169 16.8667 41.5599 17.3719 40.9223 18.0088C40.2847 18.6456 39.7789 19.4017 39.434 20.2337C39.089 21.0658 38.9115 21.9575 38.9117 22.8581C38.9137 23.242 38.948 23.6251 39.014 24.0033C39.2843 25.5997 40.1118 27.0491 41.3498 28.0945C42.5879 29.14 44.1566 29.714 45.7779 29.7148C47.3858 29.7141 48.9424 29.1496 50.1762 28.1199C51.4099 27.0902 52.2425 25.6606 52.5287 24.0806C52.6038 23.6774 52.6424 23.2682 52.6442 22.8581C52.6443 21.9575 52.4669 21.0658 52.1219 20.2337C51.7769 19.4017 51.2712 18.6456 50.6336 18.0088C49.9959 17.3719 49.2389 16.8667 48.4058 16.5221C47.5727 16.1774 46.6797 16 45.7779 16Z" fill={c.l2}/>
        <path d="M96.1338 22.8568C99.9259 22.8568 103 19.7869 103 16C103 12.2131 99.9259 9.14322 96.1338 9.14322C92.3417 9.14322 89.2675 12.2131 89.2675 16C89.2675 19.7869 92.3417 22.8568 96.1338 22.8568Z" fill={c.oc}/>
        <path d="M61.8 34.2852C60.1727 34.284 58.5984 34.8624 57.3599 35.9165C56.5992 36.5591 55.988 37.3597 55.569 38.2624C55.15 39.1651 54.9332 40.1483 54.9338 41.1432C54.9338 42.0437 55.1114 42.9353 55.4564 43.7672C55.8015 44.5991 56.3073 45.355 56.9448 45.9917C57.5824 46.6284 58.3394 47.1335 59.1724 47.4781C60.0055 47.8227 60.8983 48 61.8 48C63.3435 48.0006 64.8418 47.4796 66.0512 46.522C66.8663 45.8816 67.5252 45.0647 67.9782 44.133C68.4312 43.2012 68.6664 42.179 68.6662 41.1432C68.6664 40.2427 68.4889 39.3509 68.144 38.5189C67.799 37.6868 67.2932 36.9308 66.6556 36.2939C66.018 35.6571 65.261 35.1519 64.4279 34.8072C63.5948 34.4626 62.7018 34.2852 61.8 34.2852Z" fill={c.lc}/>
      </g>
      <defs>
      <clipPath id="clip0_6_18">
        <rect width="103" height="48" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

const white = {
  h: 'white',
  h2: 'white',
  hc: '#FFA6B3',
  l: 'white',
  l2: 'white',
  lc: '#FFA6B3',
  o: 'white',
  oc: '#FFA6B3'
}

const colorFull = {
  h: '#F36273',
  h2: '#104A6',
  hc: '#F36273',
  l: '#F48998',
  l2: '#F48998',
  lc: '#F9C5CE',
  o: '#FFA6B3',
  oc: '#FFA6B3',
}

/* eslint-enable */
export default Logo
