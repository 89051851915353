import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { checkGoogle, checkApple, checkMicrosoft } from 'app/firebaseApp'
import { http, error, firebase, storage } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'
import lang from './Lang'

const original = {
  email: '',
  name: '',
  surname: '',
}

const PublicUrl = `${config.api}/v1/public/account/employee`
let state
// let checkingLine = false
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
      feature: {},
      company: {},
    })

    state = this
    http.setMember(this)
  }

  reset() {
    runInAction(() => {
      state.user = cloneDeep(original)
      state.feature = {}
      state.company = {}
    })
  }

  isLogin() {
    return this.user.user_id !== undefined
  }

  async getCurrentUser() {
    return await firebase.onAuthStateChanged()
  }

  async checkLoginUser() {
    try {
      const user = await this.getCurrentUser()

      if (user) {
        await this.getUserProfile({ user })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    http.setToken(token)

    const url = `${config.api}/v1/user/account/profile`
    const res = await http.get(url)

    const data = res.body
    const userInfo = data.user
    lang.setLanguage(userInfo.lang_type)
    runInAction(() => {
      state.user = userInfo
      state.feature = data.feature
      state.company = data.company
    })
  }

  async setLanguage(lang_type) {
    const url = `${config.api}/v1/user/account/profile/lang`
    await http.put(url, { json: { lang_type } })

    lang.setLanguage(lang_type)
    runInAction(() => {
      state.user.lang_type = lang_type
    })
  }

  async logout() {
    await firebase.logout()
    this.reset()
  }

  setProfile(doc) {
    runInAction(() => {
      state.user = doc
    })
  }

  setAllProfile(user = {}, feature = {}, company = {}) {
    runInAction(() => {
      state.user = user
      state.feature = feature
      state.company = company
    })
  }

  async updateProfile(json = {}) {
    const url = `${config.api}/v1/user/account/employee/detail`

    const res = await http.put(url, { json })
    const data = res.body || {}

    runInAction(() => {
      state.user = data
    })
  }

  async loginByGoogle() {
    const result = await checkGoogle()

    const { user } = result
    error.isNull(user, 'user not found')
    return await this.getUserProfile({ user })
  }

  async loginByApple() {
    const result = await checkApple()

    const { user } = result
    error.isNull(user, 'user not found')
    return await this.getUserProfile({ user })
  }

  async loginByMircosoft() {
    const result = await checkMicrosoft()

    const { user } = result
    error.isNull(user, 'user not found')
    return await this.getUserProfile({ user })
  }

  async checkEmail({ email = '', mode }) {
    email = email.trim()
    const resp = await firebase.fetchSignInForEmail(email)

    const have = resp.length > 0
    return have
  }

  async loginByEmail(params = {}) {
    const { remember, password } = params
    const email = params.email.trim()

    const resp = await this.checkEmail({ email })
    if (resp === false) {
      error.lunch(`This's email ${email} not found`)
      return
    }

    if (remember) {
      storage.save('remember', { remember, email })
    } else {
      storage.remove('remember')
    }

    const user = await firebase.signInWithEmail(email, password)
    return this.getUserProfile({ user })
  }

  async resetPassword(email) {
    const url = `${PublicUrl}/reset-password`
    await http.put(url, { json: { email } })
  }

  async updatePassword(params = {}) {
    const { password } = params

    const url = `${config.api}/v1/user/account/password`
    await http.put(url, { json: { password } })
  }

  async verifyNewJoiner(params = {}) {
    const { ref_code, code } = params

    const url = `${PublicUrl}/new-joiner/${ref_code}/${code}`

    const res = await http.get(url, {})
    const data = res.body || {}
    const { user, company, feature, token } = data

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    this.setAllProfile(user, feature, company)
  }

  async verifyCode(params = {}) {
    const { ref_code, code } = params

    const url = `${PublicUrl}/verify/${ref_code}/${code}`

    const res = await http.get(url, {})
    const data = res.body || {}
    const { user, company, feature, token } = data

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    this.setAllProfile(user, feature, company)
  }
}

export default new Member()
/*
  async login(id) {
    if (checkingLine) {
      return
    }
    checkingLine = true
    error.isNull(id, 'เกิดข้อผิดพลาด account id ของคุณ')
    const url = `${PublicUrl}/login-line/${id}`

    const json = {}
    const res = await http.post(url, { json })
    const { user, token } = res.body || {}
    error.isNull(user, 'เกิดข้อผิดพลาด account ของคุณ')
    error.isNull(token, 'เกิดข้อผิดพลาด account ของคุณ')

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    checkingLine = false
    runInAction(() => {
      state.user = user
    })

    return user
  }

  async lineLogin({ redirect, type, option, navigate } = {}) {
    const { origin = '' } = window.location
    await firebase.logout()

    if (origin === 'http://localhost:2240') {
      const user = await this.login('Ua500db59cc5d6a4195b49dc87acbba66')
      const name = user.name || ''
      if (name === '') {
        navigate('/register')
      } else {
        navigate('/dashboard')
      }
    } else {
      line.login({ redirect, type, option })
    }
  }

  async checkLineProfile() {
    const resp = await line.getProfile()
    const { profile = {}, setting = {} } = resp || {}

    const user = await this.login(profile.userId)
    return { user, setting }
  }
*/
