const Logo = (props) => {
  const { size = 1, color = '#F36273' } = props

  const width = `${Math.ceil(21 * size)}`
  const height = `${Math.ceil(26 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M10.5293 2C6.60907 2 3.42315 5.18909 3.42315 9.11322V12.5394C3.42315 13.2626 3.11522 14.3651 2.74807 14.9816L1.38606 17.246C0.545168 18.6449 1.1255 20.198 2.66516 20.7196C7.76974 22.4268 13.277 22.4268 18.3816 20.7196C19.8146 20.2454 20.4423 18.5501 19.6607 17.246L18.2987 14.9816C17.9433 14.3651 17.6354 13.2626 17.6354 12.5394V9.11322C17.6354 5.20095 14.4377 2 10.5293 2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13 2C11.3658 1.72666 9.63417 1.72666 8 2C8.39189 1.4127 9.36487 1 10.5 1C11.6351 1 12.6081 1.4127 13 2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 22C14 23.65 12.425 25 10.5 25C9.54333 25 8.65667 24.66 8.02667 24.12C7.37118 23.5573 7.00209 22.7952 7 22"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default Logo
