import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Remove } from 'icon'
import { format } from 'utils'

import Invalid from './Invalid'
import Information from './Information'

const Input = (props) => {
  const [isActive, setActive] = useState(false)
  const theme = useTheme()

  const {
    name,
    label,
    value = '',
    type = 'text',
    tag,
    disabled = false,
    clear = false,
    pattern,
    charPattern,
    isNumber,
    limit = 900000,
    onChange = () => {},
    invalid,
    information,
  } = props

  const onActive = () => setActive(true)
  const onInactive = () => setActive(false)

  const onValue = (evt) => {
    if (onChange) {
      let val = evt.target.value
      if (pattern) {
        val = format.clearPattern(val, charPattern)
      }

      if (isNumber && val !== '') {
        const tmp = +val
        val = tmp === 0 || tmp ? val : value
      }

      if (val.length <= limit) {
        onChange(val, name, tag)
      }
    }
  }

  const onClear = () => {
    if (onChange) {
      onChange(isNumber ? '0' : '', name, tag)
    }
  }

  const suffix =
    value === '' || clear === false ? undefined : (
      <Btn onClick={onClear}>
        <Remove color={theme.color_level.grey.high} />
      </Btn>
    )

  const text = pattern ? format.toPattern(value, pattern) : value + ''
  const invalidCss = invalid ? ' invalid' : ''
  const activeCss = isActive ? ' active' : ''
  const disableCss = disabled ? ' disabled' : ''

  return (
    <>
      <Content className={`${invalidCss}${activeCss}${disableCss}`}>
        <Form className={disabled && text.length > 0 ? 'active' : ''}>
          <InputValue
            id={name}
            className={`${invalidCss}${activeCss}${disableCss}`}
            type={type}
            autoComplete="off"
            required
            disabled={disabled}
            value={text}
            onChange={onValue}
            onFocus={onActive}
            onBlur={onInactive}
          />
          <Label htmlFor="name" className="label-name">
            <ContentName className={`content-name ${invalidCss}`}>
              {label}
            </ContentName>
          </Label>
          {suffix}
        </Form>
      </Content>
      <Information text={information} />
      <Invalid invalid={invalid} />
    </>
  )
}

const Content = styled.div`
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 6px 10px;
  border: 1px solid ${(p) => p.theme.input.border};

  &.active {
    border: 1px solid ${(p) => p.theme.color.black};
  }

  &.disabled {
    background-color: ${(p) => p.theme.input.disable};
  }

  &.invalid {
    border: 1px solid ${(p) => p.theme.color.error};
  }
`

const Form = styled.div`
  position: relative;
  &.active input:disabled + .label-name .content-name,
  input:focus + .label-name .content-name,
  input:valid + .label-name .content-name {
    transform: translateY(-75%);
    color: ${(p) => p.theme.color_level.grey.medium};
    bottom: 0px;
  }
`

const InputValue = styled.input`
  width: 100%;
  height: 100%;
  padding: 15px 0px 0px 0px;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: white;

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }

  &:disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Label = styled.label`
  position: absolute;
  bottom: 4px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: 14px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
  }
`

const ContentName = styled.span`
  position: absolute;
  bottom: 2px;
  left: 0px;
  transition: all 0.3s ease;
  color: ${(p) => p.theme.color_level.grey.high};

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }
`

const Btn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 10px;
`

export default Input
