import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const attendance_url = `${config.api}/v1/user/attendance`

let state
export class Team extends BaseStore {
  constructor() {
    super()
    this.observable({
      active: {
        list: [],
        my_team_list: [],
      },
      list: [],
    })

    state = this
  }

  async getActiveAttendance() {
    const start = timer.getDate().toISOString()

    const url = `${attendance_url}/team/active/${start}`
    const res = await http.get(url)

    const { list, my_team_list } = res.body || {}
    runInAction(() => {
      state.active = {
        list,
        my_team_list,
      }
    })
  }

  async getAttendance() {
    const start = timer.getDate().toISOString()

    const url = `${attendance_url}/team/${start}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.list = list
    })
  }

  async getMyTeamAttendance() {
    const start = timer.getDate().toISOString()

    const url = `${attendance_url}/team/my-team/${start}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.list = list
    })
  }
}

export default new Team()
