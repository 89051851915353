import styled from 'styled-components'

import { Radio } from 'icon'

import Invalid from './Invalid'

const RadioInput = (props) => {
  const {
    name,
    label,
    labelNewLine,
    value = '',
    tag,
    gap,
    menu = [],
    onChange = () => {},
    disabled,
    invalid,
  } = props

  const onValue = (val) => {
    onChange(val, name, tag)
  }

  const content = menu.map((it, i) => {
    const isActive = it.value === value
    const color = isActive ? undefined : 'white'
    const click = disabled ? undefined : () => onValue(it.value)
    const cssActive = isActive ? 'active' : ''
    const cssDisabled = disabled ? 'disabled' : ''
    return (
      <Btn className={`${cssActive} ${cssDisabled}`} onClick={click} key={i}>
        <Radio color={color} />
        <label>{it.name}</label>
      </Btn>
    )
  })

  const css = labelNewLine ? 'new-line' : ''
  const title = label ? <Title className={css}>{label}</Title> : undefined
  const style = gap ? { columnGap: `${gap}px` } : undefined
  return (
    <>
      <PageView className={css}>
        {title}
        <ContentView className={css} style={style}>
          {content}
        </ContentView>
      </PageView>
      <Invalid invalid={invalid} />
    </>
  )
}

const PageView = styled.div`
  display: flex;
  align-items: center;

  &.new-line {
    display: block;
  }
`

const ContentView = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;

  &.new-line {
    padding-top: 8px;
  }
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  label {
    margin-left: 9px;
  }

  svg {
    border: 1px solid ${(p) => p.theme.color.border};
    border-radius: 50%;
  }

  &.active svg {
    border: 0px solid ${(p) => p.theme.color.border};
  }

  &.disabled {
    cursor: default;
  }
`

const Title = styled.span`
  margin-right: 20px;

  &.new-line {
    margin-right: 0px;
  }
`

export default RadioInput
