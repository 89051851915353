import { useTheme } from 'styled-components'

import BaseBtn from './BaseBtn'

const Btn = (props) => {
  const theme = useTheme()
  return (
    <BaseBtn
      {...props}
      color={theme.color_level.grey.dark}
      backgroundColor="white"
      border={theme.color.border}
    />
  )
}

export default Btn
