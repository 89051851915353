/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#2A9C9D' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.53 0 10 0ZM10.03 14.23C7.69 14.23 5.8 12.34 5.8 10C5.8 7.66 7.69 5.77 10.03 5.77C12.37 5.77 14.26 7.66 14.26 10C14.26 12.34 12.37 14.23 10.03 14.23Z" fill={color}/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
