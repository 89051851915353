import { useTheme } from 'styled-components'

import BaseBtn from './BaseBtn'

const Btn = (props) => {
  const theme = useTheme()
  return (
    <BaseBtn
      {...props}
      color={theme.color.white}
      backgroundColor={theme.color.green}
    />
  )
}

export default Btn
