import { http, error, firebase } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'
import member from './Member'

const PublicUrl = `${config.api}/v1/public/account/employee`

export class Phone extends BaseStore {
  constructor() {
    super()
    this.observable({
      feature: {},
    })
  }

  async sendOtp(json = {}) {
    const url = `${PublicUrl}/login-phone`

    await http.post(url, { json })
  }

  async verifyOtp(json = {}) {
    const url = `${PublicUrl}/login-otp`

    const res = await http.post(url, { json })
    const data = res.body || {}
    const { user, company, feature, token } = data

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    member.setAllProfile(user, feature, company)
  }
}

export default new Phone()
