import styled from 'styled-components'

import { Layout } from 'components/display'

import Form from './Form'

const LoginPage = (props) => {
  return (
    <Layout>
      <PageView>
        <Form />
      </PageView>
    </Layout>
  )
}

const PageView = styled.div`
  padding: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default LoginPage
