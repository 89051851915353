import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
  address: '',
  tumbon: '',
  district: '',
  province: '',
  postcode: '',
  mobile: '',
  email: '',
}

let state
export class Company extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })

    state = this
  }

  async getCompanyInfo() {
    const url = `${config.api}/v1/user/account/company`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.doc = data
    })
  }
}

export default new Company()
