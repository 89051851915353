import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Department extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
    })
    state = this
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/user/account/department`
    const resp = await http.get(url)

    const list = resp.body || []
    const menus = list.map((it) => {
      const { name } = it
      return { name, value: name, tag: it }
    })

    runInAction(() => {
      state.menu_list = menus
    })
  }
}

export default new Department()
