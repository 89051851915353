import {
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth'

import { firebase } from '../utils'

export const checkEmail = async ({ email = '', mode }) => {
  const auth = firebase.auth()
  email = email.trim()
  const resp = await fetchSignInMethodsForEmail(auth, email)

  const have = resp.length > 0
  return have
}

export const loginEmail = async (email, password, { isNotSave } = false) => {
  const auth = firebase.auth()
  if (isNotSave) await auth.setPersistence('none')

  await signInWithEmailAndPassword(auth, email, password)
  const user = auth.currentUser

  return user
}

export const checkFacebook = async () => {
  const auth = firebase.auth()
  // Start a sign in process for an unauthenticated user.
  const provider = new FacebookAuthProvider()
  const result = await signInWithPopup(auth, provider)
  const { user = {}, additionalUserInfo = {} } = result
  const { profile = {} } = additionalUserInfo

  const data = {
    uid: user.uid,
    facebook_id: profile.id,
    name: profile.first_name || user.displayName,
    surname: profile.last_name,
    email: profile.email || user.email,
    img_url: user.photoURL,
  }

  const token = await user.getIdToken()
  return { token, user, data }
}

export const checkGoogle = async () => {
  const auth = firebase.auth()
  const provider = new GoogleAuthProvider()
  // Start a sign in process for an unauthenticated user.
  provider.addScope('profile')
  provider.addScope('email')

  const result = await signInWithPopup(auth, provider)

  const { user } = result ? result : {}

  const data = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    img_url: user.photoURL,
  }

  const token = user ? await user.getIdToken() : null
  return { token, user, data }
}

export const checkApple = async () => {
  const auth = firebase.auth()
  const provider = new OAuthProvider('apple.com')
  // Start a sign in process for an unauthenticated user.
  provider.addScope('name')
  provider.addScope('email')

  const result = await signInWithPopup(auth, provider)
  const { user } = result ? result : {}

  const data = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    img_url: user.photoURL,
  }

  const token = user ? await user.getIdToken() : null
  return { token, user, data }
}

export const checkMicrosoft = async () => {
  const auth = firebase.auth()
  const provider = new OAuthProvider('microsoft.com')
  // Start a sign in process for an unauthenticated user.
  provider.addScope('mail.read')

  const result = await signInWithPopup(auth, provider)
  const { user } = result ? result : {}

  const data = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    img_url: user.photoURL,
  }

  const token = user ? await user.getIdToken() : null
  return { token, user, data }
}
