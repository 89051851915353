import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'
import { Add } from 'icon'

const AddLine = (props) => {
  const { loading, text = 'Add', onClick } = props

  return (
    <Button>
      <PrimaryBtn loading={loading} text={text} onClick={onClick} size="md">
        <Text>
          <Add /> {text}
        </Text>
      </PrimaryBtn>
    </Button>
  )
}

const Button = styled.div`
  width: 100%;
  max-width: 180px;
  margin-bottom: 24px;
`

const Text = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AddLine
