import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user/leave-request`

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'draft',
  leave_type_id: undefined,
  leave_name: '',
  leave_category: undefined,
  leave_type: 'one',
  start_date: undefined,
  start_type: 'full',
  end_date: undefined,
  end_type: 'full',
  reason: '',
  note: '',

  leave_day: 1,
  file_url: undefined,
  sent_email: 0,
  updated_balance: 0,
  action_by: undefined,
  action_at: undefined,
  created_at: undefined,
  updated_at: undefined,
}

const group_type = {
  date: undefined,
  group_list: [
    { name: 'sick', list: [] },
    { name: 'business', list: [] },
    { name: 'vacation', list: [] },
    { name: 'information', list: [] },
    { name: 'other', list: [] },
  ],
}

const createGroupItem = ({ date, list }) => {
  const group = cloneDeep(group_type)
  const unix = date.unix()
  list.forEach((item) => {
    const start_date = timer.get(item.start_date).startOf('day').unix()
    const end_date = timer.get(item.end_date).startOf('day').unix()

    if (unix >= start_date && unix <= end_date) {
      const name = item.leave_category
      const index = group.group_list.findIndex((it) => {
        return it.name === name
      })

      if (index >= 0) {
        group.group_list[index].list.push(item)
      }
    }
  })

  const newList = []
  group.group_list.forEach((item) => {
    if (item.list.length > 0) {
      newList.push(item)
    }
  })

  group.date = date
  group.group_list = newList

  return { group }
}

const groupItem = ({ start, end, list }, holiday_list = []) => {
  const result = []
  const num = end.diff(start, 'days')
  let run_date = start.startOf('day').clone()
  for (let i = 0; i <= num; i++) {
    const date = run_date.clone()

    if (timer.isHoliday(date, holiday_list) === false) {
      let { group } = createGroupItem({ date, list })

      if (group.group_list.length > 0) {
        result.push(group)
      }
    }

    run_date = run_date.add(1, 'day')
  }

  return result
}

let state
export class Request extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      leave_type: undefined,
      available_day: undefined,
      team_leave_list: [],
      leave_list: [],
    })

    state = this
  }

  reset(holiday_list = []) {
    const doc = cloneDeep(original)
    const start_date = timer.getDateNoHoliday(timer.getDate(), holiday_list)
    doc.start_date = start_date

    const next = start_date.clone().add(1, 'days')
    const end_date = timer.getDateNoHoliday(next, holiday_list)
    doc.end_date = end_date

    runInAction(() => {
      state.doc = doc
      state.leave_type = undefined
      state.available_day = undefined
    })
  }

  setDoc(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }

  setDocLeaveType(doc, leave_type) {
    runInAction(() => {
      state.doc = doc
      state.leave_type = leave_type
    })
  }

  setAvailableDay(val) {
    runInAction(() => {
      state.available_day = val
    })
  }

  async submitLeaveRequest(json = {}) {
    const url = `${Url}/leave`
    await http.post(url, { json })

    this.reset()
  }

  async getLeaveTeamList({ start, end } = {}, holiday_list = []) {
    const s = start.toISOString()
    const e = end.toISOString()

    const url = `${Url}/team/${s}/${e}`
    const res = await http.get(url)

    const list = res.body || []
    const newList = groupItem({ start, end, list }, holiday_list)
    runInAction(() => {
      state.team_leave_list = newList
    })
  }

  async getMyLeaveList({ date } = {}) {
    const s = date.clone().startOf('month').toISOString()
    const e = date.clone().endOf('month').toISOString()

    const url = `${Url}/leave?start_date=${s}&end_date=${e}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.leave_list = list
    })
  }

  async cancelLeaveReq({ leave_request_id, reason } = {}) {
    const url = `${Url}/leave/${leave_request_id}/cancel`
    const res = await http.put(url, { json: { reason } })
    const { leave_list = [] } = this.toJS()

    const i = leave_list.findIndex(
      (it) => it.leave_request_id === leave_request_id
    )
    if (i !== -1) {
      const newDoc = res.body
      const oldDoc = leave_list[i]
      leave_list[i] = { ...oldDoc, ...newDoc }
      runInAction(() => {
        state.leave_list = leave_list
      })
    }
  }
}

export default new Request()

/*
{
  leave_request_id: 1,
  company_id: 1,
  user_id: 1,
  status: 'approved',
  file_url: null,
  note: 'Sorry',
  leave_type_id: 5,
  leave_name: '',
  leave_category: 'sick',
  leave_type: 'one',
  start_date: '2022-07-31T17:00:00.000Z',
  start_unix: 1659286800,
  start_type: 'full',
  end_date: '2022-07-31T17:00:00.000Z',
  end_unix: 1659286800,
  end_type: 'full',
  leave_day: 1,
  sent_email: 0,
  sent_slack: 0,
  balance_status: 'added',
  reject_reason: null,
  cancel_reason: null,
  cancel_user_id: null,
  approved_user_id: null,
  reject_user_id: null,
  cancel_at: null,
  approved_at: '2022-07-30T11:20:34.000Z',
  reject_at: null,
  created_at: '2022-07-30T11:20:33.000Z',
  updated_at: '2022-07-30T11:20:33.000Z',
  holiday: [],
  name: 'Uthai',
  nickname: 'Tong+',
  surname: 'Rattanapacksopon',
  img_url: null,
}
*/
