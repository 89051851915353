const Logo = (props) => {
  const { size = 1, color = '#F36273' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4 10H13.6M10 13.6V6.4M10 19C14.95 19 19 14.95 19 10C19 5.05 14.95 1 10 1C5.05 1 1 5.05 1 10C1 14.95 5.05 19 10 19Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Logo
