import styled from 'styled-components'

import { Next } from 'icon'

const NextBtn = (props) => {
  const { text, color } = props

  return (
    <PageView>
      <Text style={{ color }}>{text}</Text>
      <Next size={0.6} />
    </PageView>
  )
}

const PageView = styled.div`
  width: fit-content;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 4px;
`

const Text = styled.span`
  color: #3f3f3f;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
`

export default NextBtn
