import { Link as RLink } from 'react-router-dom'

const Link = (props) => {
  const { to, style, className, children } = props
  const css = {
    color: 'inherit',
    ...style,
  }
  return (
    <RLink to={to} style={css} className={className}>
      {children || 'Click'}
    </RLink>
  )
}

export default Link
