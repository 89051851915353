import styled from 'styled-components'

import Loading from 'components/loading'

const Layout = (props) => {
  const {
    children,
    loading,
    isHeader,
    isFooter,
    isPadding = true,
    background,
  } = props
  const css = {}

  if (isPadding) {
    css.paddingLeft = '20px'
    css.paddingRight = '20px'
  }

  if (isHeader) {
    css.paddingTop = '65px'
  }

  if (isFooter) {
    css.paddingBottom = '120px'
  }

  if (background) {
    css.backgroundColor = background
  }
  return (
    <PageView style={css}>
      <Loading loading={loading}>{children}</Loading>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.app_max};
  margin: 0 auto;
`

export default Layout
