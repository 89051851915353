import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  email: validator.getRuleString('ชื่อ'),
  password: validator.getRuleString('นามสกุล'),
}).options({ allowUnknown: true })

export default role
