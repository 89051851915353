import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Team extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
    })
    state = this
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return menu_list
    }

    const url = `${config.api}/v1/user/account/project/full`
    const resp = await http.get(url)

    const list = resp.body || []
    const menus = list.map((it) => {
      const { project_id, project_name } = it
      return { name: project_name, value: project_id, tag: it }
    })

    runInAction(() => {
      state.menu_list = menus
    })

    return menus
  }
}

export default new Team()
