import styled from 'styled-components'

import { Radio, RadioBlank } from 'icon'

const RadioInput = (props) => {
  const { name, label, value, tag, onChange = () => {} } = props

  const onValue = () => {
    onChange(value, name, tag)
  }

  const icon = value ? <Radio /> : <RadioBlank />
  return (
    <Btn onClick={onValue}>
      {icon}
      <label>{label}</label>
    </Btn>
  )
}

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  label {
    margin-left: 15px;
  }
`

export default RadioInput
