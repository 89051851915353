if (typeof window === 'undefined')
  global.window = { location: { host: 'testhost' } }

const maps = {
  'hlo.app': 'production',
  'hlo-app.firebaseapp.com': 'production',
  'test.hlo.app': 'production',
  'hlo-testing.web.app': 'staging',
  'hlo-testing.firebaseapp.com': 'staging',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'localhost'
export const config = require(`./config.${env}`)
